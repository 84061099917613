import axios from "../axios-client";
import {
  GET_COST_ESTIMATES_VIEW_URL,
  DELETE_APPROVER,
} from "../../resources/constants";

class costEstimatesViewService {
  public static async getCostEstimatesView(id: string) {
    return await axios.get(GET_COST_ESTIMATES_VIEW_URL + "/" + id);
  }
  public static async deleteApprover(id: string) {
    return await axios.del(DELETE_APPROVER + "/" + id);
  }
}

export default costEstimatesViewService;
