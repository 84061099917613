import axiosClient from "../axios-client";
import {
  TEMP_LIVING_OPTION_DETAILS_URL,
  TEMP_LIVING_OPTIONS_URL,
  TEMP_LIVING_TRANSFEREE_LIST_URL,
  UPDATE_TLO_PREFERENCE,
  GET_PROPERTY_LISTING,
  GET_PROPERTY_DETAILS,
  GET_SUPPLIER_LIST,
  CREATE_PROPERY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
  UPLOAD_PROPERTY_IMAGES,
  UPDATE_PROPERTY_IMAGES,
  DELETE_PROPERTY_IMAGE,
  DELETE_TLO,
  UPDATE_TLO_STATUS,
  UPDATE_TLO_VISIBILITY,
  CREATE_TLO,
  UPDATE_TLO,
  CREATE_TLR,
  UPDATE_TLR,
  GET_TLR_DETAILS,
  UPDATE_PROPERTY_VISIBILITY,
  DELETE_TLR,
  SET_NO_OPTIONS,
} from "../../resources/constants";
import axios from "axios";

class TempLivingService {
  public static async getTempLivingTransfereeList(filters: any) {
    const response: any = await axiosClient.post(
      TEMP_LIVING_TRANSFEREE_LIST_URL,
      filters,
    );
    return response.data;
  }

  public static async getTempLivingOptions(requestId: any) {
    const response: any = await axiosClient.get(
      TEMP_LIVING_OPTIONS_URL + "/" + requestId,
    );
    return response.data.tlr;
  }

  public static async getTempLivingOptionDetails() {
    const response: any = await axiosClient.get(TEMP_LIVING_OPTION_DETAILS_URL);
    return response.data;
  }

  public static async getTempLivingRequestDetails(id: any) {
    const response: any = await axiosClient.get(GET_TLR_DETAILS + "/" + id);
    return response.data;
  }

  public static async updatePreference(data: any) {
    return axiosClient.post(UPDATE_TLO_PREFERENCE, data);
  }

  public static async getPropertyListing(data: any) {
    return await axiosClient.get(
      GET_PROPERTY_LISTING + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getPropertyDetails(id: string) {
    return await axiosClient.get(GET_PROPERTY_DETAILS + "/" + id);
  }

  public static async getSupplier() {
    return await axiosClient.get(GET_SUPPLIER_LIST);
  }

  public static async deleteProperty(id: string) {
    return await axiosClient.del(DELETE_PROPERTY + "/" + id);
  }

  public static async toggleProperty(id: string) {
    return await axiosClient.post(UPDATE_PROPERTY_VISIBILITY + "/" + id, {});
  }

  public static async deletePropertyImage(id: string) {
    return await axiosClient.del(DELETE_PROPERTY_IMAGE + "/" + id);
  }

  public static async createProperty(data: any) {
    return axiosClient.put(CREATE_PROPERY, data);
  }

  public static async updateProperty(data: any) {
    return axiosClient.post(UPDATE_PROPERTY, data);
  }

  public static async createTlo(data: any) {
    return axiosClient.put(CREATE_TLO, data);
  }

  public static async updateTlo(data: any) {
    return axiosClient.post(UPDATE_TLO, data);
  }

  public static async uploadImage(data: any) {
    return await axiosClient.post(UPLOAD_PROPERTY_IMAGES, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async updateImage(data: any) {
    return await axiosClient.post(UPDATE_PROPERTY_IMAGES, data);
  }

  public static async updateTloStatus(data: any) {
    return await axiosClient.post(UPDATE_TLO_STATUS, data);
  }

  public static async updateTloVisibility(data: any) {
    return await axiosClient.post(
      UPDATE_TLO_VISIBILITY + "/" + data.optionId,
      data,
    );
  }

  public static async setNoOptions(data: any) {
    return await axiosClient.post(SET_NO_OPTIONS, data);
  }

  public static async deleteTlo(id: string) {
    return await axiosClient.del(DELETE_TLO + "/" + id);
  }

  public static async deleteTLR(id: string) {
    return await axiosClient.del(DELETE_TLR + "/" + id);
  }

  public static async createTLR(data: any) {
    if (data?.id) return await axiosClient.post(UPDATE_TLR, { tlr: data });
    else {
      delete data.id;
      return await axiosClient.put(CREATE_TLR, { tlr: data });
    }
  }
}

export default TempLivingService;
