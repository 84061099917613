import axiosClient from "../axios-client";
import {
  CREATE_VENDOR_URL,
  DELETE_VENDOR,
  GET_VENDOR_URL,
  UPDATE_VENDOR_URL,
  VENDOR_SERVICES_URL,
  VENDORS_URL,
} from "../../resources/constants";
import axios from "../axios-client";

class VendorsService {
  public static async getAllVendors(params: any) {
    try {
      const { data }: { data: any } = await axiosClient.get(
        VENDORS_URL + "?" + new URLSearchParams(params).toString(),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getAllVendorServices() {
    try {
      const { data }: { data: any } =
        await axiosClient.get(VENDOR_SERVICES_URL);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getVendorDetails(data: any) {
    return await axios.get(GET_VENDOR_URL + "/" + data.vendorId);
  }

  public static async createVendor(data: any) {
    return await axios.post(CREATE_VENDOR_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async updateVendor(data: any) {
    return await axios.post(UPDATE_VENDOR_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async deleteVendor(data: any) {
    return await axios.del(DELETE_VENDOR + "/" + data.id);
  }
}

export default VendorsService;
