import React, { Component, ErrorInfo, ReactNode, createRef } from "react";
import PageNotFound from "../PageNotFound/PageNotFound";
import { connect } from "react-redux";
import logger from "../../services/logger";

interface Props {
  children?: ReactNode;
  login?: any;
}

interface State {
  hasError: boolean;
}

let isLogSent = false;
class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    if (this.props?.login?.loggedIn && !isLogSent) {
      isLogSent = true;
      logger.set({
        logMessage: {
          type: "error",
          message: JSON.stringify({
            url: window.location.href,
            error: errorInfo,
            login: this.props?.login,
            time: new Date().toISOString(),
            env: process.env.NODE_ENV,
          }),
        },
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return <PageNotFound code={"maintenence"} />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: any) => ({
  login: state.login,
});

export default connect(mapStateToProps)(ErrorBoundary);
