import {
  GET_FORGOT_PASSWORD_URL,
  GET_FORGOT_USERNAME_URL,
  GET_USER_INFO_URL,
  loginUrl,
  SEND_GET_STARTED_EMAIL,
  SEND_WELCOME_EMAIL,
  signUpUrl,
} from "../../resources/constants";
import axios from "../axios-client";

export interface loginPageResponse {
  data?: any;
  success?: boolean;
  message?: string;
  error?: any;
  userRoles?: any;
  userInfo?: any;
}

export default class loginServices {
  public username: string;
  public password: string;

  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }

  static async login(username: string, password: string) {
    const body = {
      username,
      password,
    };
    return await axios.post(loginUrl, body, {
      headers: { encrypted: 1 },
    });
  }

  static async sendWelcomeEmail(transfereeId: any) {
    return await axios.get(SEND_WELCOME_EMAIL + "?userId=" + transfereeId);
  }

  static async sendGetStartedEmail() {
    const result: any = await axios.get(SEND_GET_STARTED_EMAIL);
    return result?.data;
  }

  static async signUp(payload: any) {
    const body = {
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      verificationCode: payload.verificationCode,
    };
    const result: any = await axios.post(signUpUrl, body, {
      headers: { encrypted: 1 },
    });
    return result;
  }

  static async forgotPassword(userName: any) {
    const result: any = await axios.get(
      `${GET_FORGOT_PASSWORD_URL}?username=${userName}`,
    );
    return result;
  }

  static async forgotUserName(email: any) {
    const result: any = await axios.get(
      `${GET_FORGOT_USERNAME_URL}?email=${email}`,
    );
    return result?.data;
  }

  static async getUserInfo() {
    const result: any = await axios.get(GET_USER_INFO_URL);
    return result?.data?.profileInfo;
  }

  public static async getLoginDetails(username: string, password: string) {
    const result: any = loginServices.login(username, password);
    return result;
  }

  public static async getsignUpDetails(
    password: string,
    confirmPassword: string,
  ) {
    return await loginServices.login(password, confirmPassword);
  }

  public static async getUserDetails() {
    return await loginServices.getUserInfo();
  }
}
