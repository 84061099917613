import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";
import transfereeService from "../../services/transferees";
import transfereeServices from "../../services/transferees";

export interface transfereesPageState {
  history: any;
  countryData?: any;
  transferees: {
    table: any[];
    count?: number;
  };
  loading: boolean;
  error?: string;
  transfereeDetails?: any;

  transfereeHistoryList: any;
  count: number;
  historyDetails?: any;
}

const initialState: transfereesPageState = {
  error: undefined,
  countryData: [],
  history: [],
  loading: false,
  transferees: {
    table: [],
    count: 0,
  },
  transfereeHistoryList: [],
  count: 0,
};

export const getCountries = createAsyncThunk("user/Login", async () => {
  try {
    return transfereeService.getCountries();
  } catch (error_) {
    return error_;
  }
});

export const getAllTransferees = createAsyncThunk(
  "transferees/fetch_all",
  async (body: any) => {
    const response = transfereeServices.getAllTransferees(body);

    return response;
  },
);
export const getTransfereeHistory = createAsyncThunk(
  "history/get-transferee-history",
  async (filterData: any) => {
    const response = transfereeServices.getTransfereeHistory(filterData);

    return response;
  },
);

const transfereeSlice = createSlice({
  name: "transfereesPageReduce",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getCountries.pending, (state) => {
      state.countryData = ["Andorra1", "Angola2"];
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countryData = ["Andorra3", "Angola5"];
    });
    builder.addCase(getCountries.rejected, (state, action) => {
      state.countryData = ["Andorra6", "Angola7"];
      state.error = error(action.payload);
    });

    // get transferee history
    builder.addCase(getTransfereeHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransfereeHistory.fulfilled, (state, action: any) => {
      state.loading = false;
      state.transfereeHistoryList = action.payload;
      state.count = action.payload.Count;
    });
    builder.addCase(getTransfereeHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    //get all exceptions
    builder.addCase(getAllTransferees.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTransferees.fulfilled, (state, action) => {
      state.loading = false;

      if ("transferees" in action.payload && "totalCount" in action.payload) {
        const { totalCount, transferees } = action.payload;
        state.transferees = {
          table: transferees,
          count: totalCount,
        };
      } else {
        console.error(
          "Error occurred while fetching transferees:",
          action.payload.error,
        );
      }
    });
    builder.addCase(getAllTransferees.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default transfereeSlice.reducer;
