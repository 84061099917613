import axiosClient from "../axios-client";
import {
  GET_ALL_REPORTS_URL,
  GET_CLIENT_EXPENSE_REPORT_URL,
  GET_CLOSED_REPORTS_URL,
  GET_EXCEPTIONS_REPORT_URL,
  GET_INITIATION_REPORTS_URL,
  GET_FAVOURITE_LIST,
  GET_FAV_DASHBOARD_DATA,
  GET_CLIENT_EXPENSE_BY_CATEGORY_URL,
  VENDOR_TRACKING_MOVE_HELP,
  GET_SUBMITTED_EXPENSE_REPORT,
  GET_TLR_REPORTS,
  GET_TLO_REPORTS,
  GET_EXCEPTION_REQUEST_REPORT,
} from "../../resources/constants";
import { saveAs } from "file-saver";
import axios from "axios";

const getFilterParamsAndBody = (filtersData: any) => {
  let filters = { ...filtersData };
  const params = new URLSearchParams({
    page: filters.page,
    limit: filters.limit,
    order: filters.order,
  }).toString();
  delete filters.page;
  delete filters.limit;
  delete filters.order;
  return { params, body: filters };
};
class ReportsService {
  public static async getAllReports(filters: any) {
    try {
      let filtersCopy = { ...filters };
      const params = new URLSearchParams({
        page: filtersCopy?.page || 1,
        limit: filtersCopy.limit || 10,
        order: filtersCopy.order || "ASC",
        clientId: filtersCopy.clientId,
      }).toString();
      const { data }: { data: any } = await axiosClient.get(
        GET_ALL_REPORTS_URL + "?" + params,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getClosedReports(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_CLOSED_REPORTS_URL + "?" + params,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getInitiationReports(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_INITIATION_REPORTS_URL + "?" + params,
        filters,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getExceptionReports(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_EXCEPTIONS_REPORT_URL + "?" + params,
        filters,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getClientExpenseReports(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_CLIENT_EXPENSE_REPORT_URL + "?" + params,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getTlrReports(filters: any) {
    try {
      const { data }: { data: any } = await axiosClient.post(
        GET_TLR_REPORTS,
        filters,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getTloReports(filters: any) {
    try {
      const { data }: { data: any } = await axiosClient.post(
        GET_TLO_REPORTS,
        filters,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getClientExpenseByCategory(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_CLIENT_EXPENSE_BY_CATEGORY_URL + "?" + params,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getSubmittedExepnse(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_SUBMITTED_EXPENSE_REPORT + "?" + params,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getExceptionRequest(filters: any) {
    try {
      const { params, body } = getFilterParamsAndBody(filters);
      const { data }: { data: any } = await axiosClient.post(
        GET_EXCEPTION_REQUEST_REPORT + "?" + params,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async exportReports(url: string, filters: any) {
    try {
      const response = await axios.post(url, filters, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (blob) {
        saveAs(blob, "report.xlsx");
      }
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
    }
  }

  public static async getFavouriteList(data: any) {
    return axiosClient.get(
      GET_FAVOURITE_LIST + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getFavData(filters: any) {
    return await axiosClient.post(GET_FAV_DASHBOARD_DATA, filters);
  }

  public static async vendorTrackingMoveHelp(filters: any) {
    return await axiosClient.post(VENDOR_TRACKING_MOVE_HELP, filters);
  }
}

export default ReportsService;
