import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { notificationIcons } from "./HeaderComponent.contants";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { Button, Empty, Skeleton, Space, Spin } from "antd";
import {
  clearAllIcon,
  notificationDoneIcon,
} from "../../resources/SVGImages/dashboard";
dayjs.extend(relativeTime);
import noNotiIcon from "../../resources/SVGImages/no-notification.svg";
import defautlNotiIcon from "../../resources/images/notification/default.svg";

const NotificationTray = (props) => {
  const list = props.notifications.list;
  const count = props.notifications.count;
  const [showClearAll, setShowClearAll] = useState(false);

  useEffect(() => {
    if (document.querySelector(".notificationBody .list")) {
      document
        .querySelector(".notificationBody .list")
        ?.addEventListener?.("scroll", (e: any) => {
          if (
            e.target.scrollTop + e.target.clientHeight >
            e.target.scrollHeight - 20
          ) {
            props.getOldNotifications();
          }
        });

      return () => {
        document
          .querySelector(".notificationBody .list")
          ?.removeEventListener?.("scroll", () => {});
      };
    }
  }, []);

  return (
    <div className="notificationBody">
      <div className="head">
        <h3>Notification</h3>
        <div className="headAction">
          {count > 0 && (
            <span
              className="selectNotification"
              onClick={() => {
                setShowClearAll(true);
              }}
            >
              Clear All {clearAllIcon}
            </span>
          )}
        </div>
      </div>

      {showClearAll && (
        <div className="confirmClearAll">
          <h3>Are you sure you want to clear all notifications?</h3>
          <div className="action">
            <Button
              id="clientEditCancelCancelBtn"
              key="submit"
              className="btnBlue light"
              type="primary"
              onClick={() => {
                setShowClearAll(false);
              }}
            >
              Cancel
            </Button>
            <Button
              id="clientEditCancelSubmitBtn"
              key="submit"
              className="btnRed"
              type="primary"
              onClick={() => {
                setShowClearAll(false);
                props.clearAllNotifications();
              }}
            >
              Yes, I'm sure
            </Button>
          </div>
        </div>
      )}
      {count > 0 ? (
        <div className="list">
          {list?.map((item: any, index) => {
            return (
              <div
                className="item"
                key={item.id}
                data-id={`noti-${item.id}-${item.category}`}
              >
                <div className="icon">
                  <img
                    src={
                      notificationIcons[
                        item?.notificationTemplate?.sourceType
                      ] || defautlNotiIcon
                    }
                  />
                </div>
                <div className="data">
                  <div className="title">
                    <h4>{item?.header || item?.notificationTemplate?.title}</h4>
                    {/* <div className="name">Hannah Blue</div> */}
                  </div>
                  <div className="description">
                    {item?.message || item?.content}
                  </div>
                  {item?.link && (
                    <p className="description">
                      <span
                        className="greenUnder oneLineOverflow"
                        onClick={() => {
                          if (item?.link.includes("http")) {
                            window.open(item?.link, "_blank");
                          } else {
                            window.open(`http://${item?.link}`, "_blank");
                          }
                        }}
                      >
                        {item?.link}
                      </span>
                    </p>
                  )}
                  <div className="notiFoot">
                    <div className="date">
                      {dayjs(item?.updatedAt).fromNow(true)}
                    </div>

                    <div
                      className="clearBtn"
                      onClick={() => {
                        props.clearNotification(item.id, item.category);
                      }}
                    >
                      {notificationDoneIcon}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {props?.loading && (
            <div style={{ textAlign: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
              <br />
              <br />
            </div>
          )}
        </div>
      ) : (
        <div className="noNoti">
          <img src={noNotiIcon} />
          <h3>No Notifications Yet</h3>
          <p>When you get notifications, they’ll show up here</p>
        </div>
      )}
    </div>
  );
};

export default NotificationTray;
