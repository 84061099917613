/* eslint-disable no-unused-vars */
import Environment from "../utils/environment";
import AxiosClient from "./axios-client";
import dashboardData from "../mock-data/dashboard.json";

export interface ILandingInfo {
  id: number;
  title: string;
  description: string;
  background?: string;
}

enum Landing {
  LANDING_PAGE = "landing",
}

export default class LandingServices {
  public static readonly GET_LANDING_PAGE_INFO =
    "/global/mock-data/dasboard.json";

  private static async landing() {
    const { data }: { data: any } = await AxiosClient.get(
      `${this.GET_LANDING_PAGE_INFO}`,
    );
    if (Environment.isOffline()) {
      await localStorage.setItem(Landing.LANDING_PAGE, JSON.stringify(data));
    }
    return dashboardData;
  }

  public static async getLandingPageInfo() {
    if (Environment.isOffline()) {
      const response =
        // (await Storage.getItem(Landing.LANDING_PAGE)) ||
        await LandingServices.landing();

      return response;
    } else {
      return await LandingServices.landing();
    }
  }
}
