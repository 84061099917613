import axios, { AxiosError, AxiosResponse } from "axios";
import { Notify } from "../components/Notification/Toast";
import { redirect } from "react-router-dom";
import { REFRESH_TOKEN_URL } from "../resources/constants";
import { logout } from "../utils/logout";
import { getCookie, setCookie } from "../utils/cookies";
import { decrypt, encrypt, generateKeys } from "../utils/generateKeys";
import debounce from "lodash/debounce";

export const getErrorData = (error: any) => {
  return error.response.data;
};

export const token = {
  access: "",
  publicKey: "",
  privateKey: "",
  serverPublicKey: "",
};

const BASE_URL = process.env.REACT_APP_API_BASEURL;

const rsaKeys = generateKeys();
rsaKeys.then((keys: any) => {
  token.publicKey = keys.publicKey;
  token.privateKey = keys.privateKey;
});

export const setLastURL = () => {
  let url = new URL(window.location.href);
  url.searchParams.delete("redirect");
  localStorage.setItem("redirect", url.toString());
};

const debouncedRequests = {};

axios.interceptors.request.use(async (config) => {
  config.timeout = 300000;
  config.headers.Accept = "application/json";
  config.headers["Access-Control-Allow-Origin"] = "*";

  const { method, url, params, data } = config;

  if (token.publicKey) {
    // config.headers["public-key"] = window.btoa(token.publicKey);
  }

  if (token.serverPublicKey && config.headers["encrypted"] == 1) {
    config.data = {
      payload: encrypt(token.serverPublicKey, JSON.stringify(config.data)),
    };
  }

  if (token.access) {
    config.headers!.Authorization = `Bearer ${token.access}`;

    const lastTime = window.localStorage.getItem("lastTime");
    const currentTime = new Date().getTime();

    if (!lastTime) {
      logout();
    }

    const expireTime = process.env.REACT_APP_TOKEN_EXPIRY_SECONDS || 600;

    if (currentTime > parseInt(lastTime!) + 1000 * +expireTime) {
      setLastURL();
      // logout();
    }

    window.localStorage.setItem("lastTime", new Date().getTime() + "");
  }

  const requestKey = `${method}${url}${JSON.stringify(params)}${JSON.stringify(
    data,
  )}`;

  if (config?.data instanceof FormData) {
    return config;
  }

  if (!debouncedRequests[requestKey]) {
    debouncedRequests[requestKey] = debounce(
      (resolve) => {
        delete debouncedRequests[requestKey];
        resolve(config);
      },
      300,
      { leading: false, trailing: true },
    );
  }

  return new Promise((resolve) => {
    debouncedRequests[requestKey](resolve);
  });
});

interface AxiosErrorType extends AxiosError {
  config: AxiosError["config"] & { retry?: boolean };
}

const refreshData = {
  time: 0,
  fetch: null,
  json: null,
};

axios.interceptors.response.use(
  async (response: any) => {
    if (
      response.config.headers["encrypted"] == 1 &&
      response?.data?.payload &&
      token.privateKey
    ) {
      const decryptedData = decrypt(token.privateKey, response.data.payload);
      response.data = JSON.parse(decryptedData.message);
    }

    if (response.headers?.["public-key"]) {
      token.serverPublicKey = window.atob(response.headers?.["public-key"]);
    }

    return response;
  },
  async (error: AxiosErrorType) => {
    const response: AxiosResponse = error.response!;
    const originalRequest = error.config!;

    if (!response) {
      Notify.error(
        "Please make sure you are connected to the internet and try again.",
      );
      throw error;
    }

    const { data, status, config } = response;

    if (data?.publicKey) {
      token.serverPublicKey = data.publicKey;
    }

    if (
      status === 401 &&
      !originalRequest?.retry &&
      getCookie("refreshToken")
    ) {
      originalRequest.retry = true;

      try {
        const refreshToken = getCookie("refreshToken");
        let body: any = { refreshToken };

        if (token.serverPublicKey) {
          body = {
            payload: encrypt(token.serverPublicKey, JSON.stringify(body)),
          };
        }

        if (new Date().getTime() > refreshData.time + 1000) {
          refreshData.time = new Date().getTime();

          refreshData.fetch = fetch(`${BASE_URL}/${REFRESH_TOKEN_URL}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "public-key": window.btoa(token.publicKey),
            },
            body: JSON.stringify(body),
          });
        }

        const request = await refreshData.fetch;

        if (
          (request?.status === 401 ||
            request?.status === 400 ||
            request?.status === 403 ||
            request?.status === 500) &&
          window.location.pathname !== "/login"
        ) {
          setLastURL();
          logout();
          return;
        }

        refreshData.json = !request.body.locked
          ? request.json()
          : refreshData.json;

        const response = await refreshData.json;

        if (token.serverPublicKey && response?.payload) {
          const decryptedData = decrypt(token.privateKey, response.payload);
          response.data = JSON.parse(decryptedData.message);
        }

        const responseClone = { ...response?.data, ...response };
        const { accessToken, refreshToken: newRefreshToken } = responseClone;

        token.access = accessToken;
        setCookie("refreshToken", refreshToken, 1);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${token.access}`;
        originalRequest.retry = false;
        return axios(originalRequest);
      } catch (error) {
        let url = new URL(window.location.href);
        url.searchParams.delete("redirect");
        localStorage.setItem("redirect", url.toString());
        logout();
        return;
      }
    }

    const message =
      data?.error?.message ??
      data?.details ??
      data?.message ??
      "Something went wrong";

    switch (status) {
      case 400:
        Notify.error(message ?? "Something went wrong");
        break;
      case 401:
        Notify.error(message ?? "Unauthorized");
        break;
      case 403:
        Notify.error("User cannot perform this operation", "Forbidden");
        redirect("/forbidden");
        break;
      case 404:
        Notify.error(message ?? "Not found");
        return redirect("/not-found");
        break;
      case 422:
        Notify.error(message ?? "Something went wrong");
        break;
      case 500:
        Notify.error(message ?? "Something went wrong");
        break;
    }
    throw new Error(message ?? "Something went wrong");
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, params: any = new URLSearchParams()) =>
    axios.get<T>(`${BASE_URL}${url}`, { ...params }).then(responseBody),
  post: <T>(url: string, body: {}, headers: any = {}) => {
    return axios.post<T>(`${BASE_URL}${url}`, body, headers).then(responseBody);
  },
  put: <T>(url: string, body: {}, headers: any = {}) =>
    axios.put<T>(`${BASE_URL}${url}`, body, headers).then(responseBody),
  del: <T>(url: string, body: any = {}) =>
    axios.delete<T>(`${BASE_URL}${url}`, { data: body }).then(responseBody),
};

export default requests;
