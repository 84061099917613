import axios from "../axios-client";
import { GET_TRANSFEREE_PROFILE_URL } from "../../resources/constants";

class transfereeProfileService {
  public static async getTransfereeDetails(id: string) {
    return await axios.get(GET_TRANSFEREE_PROFILE_URL + "/" + id);
  }
}

export default transfereeProfileService;
