import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import countryStateCity from "../../services/CountryStateCity/CountryStateCity";
import {
  ACTION_GET_COUNTRY,
  ACTION_GET_STATE,
  ACTION_GET_CITY,
} from "../../Actions/actions";

interface initialState {
  country: any;
  data: any;
}

export const initialState: initialState = {
  data: {
    country: {
      error: null,
      loading: true,
      data: {},
    },
    state: {
      error: null,
      loading: true,
      data: {},
    },
    city: {
      error: null,
      loading: true,
      data: {},
    },
  },
  country: undefined,
};

export const getCountry = createAsyncThunk(ACTION_GET_COUNTRY, async () => {
  return countryStateCity.getCountry();
});

export const getState = createAsyncThunk(
  ACTION_GET_STATE,
  async ({ countryId }: { countryId: string }) => {
    return countryStateCity.getState(countryId);
  },
);

export const getCity = createAsyncThunk(
  ACTION_GET_CITY,
  async ({ countryId, stateId }: { countryId: string; stateId: string }) => {
    return countryStateCity.getCity(countryId, stateId);
  },
);

const getCountryFullfield = (state: any, action: any) => {
  state.data.country.loading = false;
  state.data.country.data = action.payload?.data;
};

const getCountryPending = (state: any, action: any) => {
  state.data.country.loading = true;
  state.data.country.error = action.error ? action?.error?.message : null;
};

const getCountryRejected = (state: any, action: any) => {
  state.data.country.loading = false;
  state.data.country.error = action.error ? action?.error?.message : null;
};

const getStateFullfield = (state: any, action: any) => {
  state.data.state.loading = false;
  state.data.state.data = action.payload?.data;
};

const getStatePending = (state: any, action: any) => {
  state.data.state.loading = true;
  state.data.state.error = action.error ? action?.error?.message : null;
};

const getStateRejected = (state: any, action: any) => {
  state.data.state.loading = false;
  state.data.state.error = action.error ? action?.error?.message : null;
};

const getCityFullfield = (state: any, action: any) => {
  state.data.city.loading = false;
  state.data.city.data = action.payload?.data;
};

const getCityPending = (state: any, action: any) => {
  state.data.city.loading = true;
  state.data.city.error = action.error ? action?.error?.message : null;
};

const getCityRejected = (state: any, action: any) => {
  state.data.city.loading = false;
  state.data.city.error = action.error ? action?.error?.message : null;
};

const countryStateCitySlice = createSlice({
  name: "countryStateCity",
  initialState,
  reducers: {
    resetCity: (state: any) => {
      state.data.city.data = {};
    },
    resetState: (state: any) => {
      state.data.state.data = {};
    },
  },
  extraReducers(builder): void {
    builder.addCase(getCountry.fulfilled, getCountryFullfield);
    builder.addCase(getCountry.pending, getCountryPending);
    builder.addCase(getCountry.rejected, getCountryRejected);

    builder.addCase(getState.fulfilled, getStateFullfield);
    builder.addCase(getState.pending, getStatePending);
    builder.addCase(getState.rejected, getStateRejected);

    builder.addCase(getCity.fulfilled, getCityFullfield);
    builder.addCase(getCity.pending, getCityPending);
    builder.addCase(getCity.rejected, getCityRejected);
  },
});

export default countryStateCitySlice.reducer;
export const { resetCity, resetState } = countryStateCitySlice.actions;
