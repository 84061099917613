import {
  CONTACT_SUPPORT,
  CREATE_CLIENT,
  DELETE_CLIENT,
  DELETE_CLIENT_LOGO,
  GET_CLIENT_BY_ID,
  GET_CLIENT_CONFIGURATION_STATS,
  GET_CLIENT_FORM_DETAILS,
  GET_CLIENT_LIST_URL,
  GET_CLIENT_SUPPORT,
  UPDATE_CLIENT,
  CLIENT_CHANGE_LOG,
} from "../../resources/constants";
import axios from "../axios-client";

export interface ClientPageState {
  data?: any;
  success?: boolean;
  error?: any;
  Environment?: any;
  clientDetails: any;
  client: any;
}

const initialState: ClientPageState = {
  data: [],
  success: false,
  error: undefined,
  Environment: undefined,
  clientDetails: null,
  client: null,
};

export default class ClientServices {
  //get exception details
  public static async getClients(filtersData: any) {
    return await axios.get(
      GET_CLIENT_LIST_URL + "?" + new URLSearchParams(filtersData).toString(),
    );
  }

  public static async getClientConfigurationStats(filtersData: any) {
    return await axios.get(
      GET_CLIENT_CONFIGURATION_STATS +
        "?" +
        new URLSearchParams(filtersData).toString(),
    );
  }

  public static async getClientDetails(clientId: string) {
    return await axios.get(GET_CLIENT_BY_ID + "/" + clientId);
  }

  public static async getClientFormDetails(id: string) {
    return await axios.get(GET_CLIENT_FORM_DETAILS + "/" + id);
  }

  public static async getClientLogs(data: any) {
    return await axios.get(
      CLIENT_CHANGE_LOG + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async createClient(data: any) {
    return await axios.put(CREATE_CLIENT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async updateClient(data: any) {
    return await axios.post(UPDATE_CLIENT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async deleteClient(id: string) {
    return await axios.del(DELETE_CLIENT + "/" + id);
  }

  public static async deleteClientLogo(id: string) {
    return await axios.del(DELETE_CLIENT_LOGO + "/" + id);
  }

  public static async getClientSupport(id: string) {
    return await axios.get(GET_CLIENT_SUPPORT + "/" + id);
  }

  public static async contactSupport(data: any) {
    return await axios.post(CONTACT_SUPPORT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
