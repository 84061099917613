import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACTION_GET_TEMP_LIVING_TRANSFEREES,
  ACTION_GET_TEMP_LIVING_OPTIONS,
} from "../../Actions/actions";
import TempLivingService from "../../services/TempLiving/tempLiving";

const initialState: any = {
  tempLivingTransfereeList: {
    table: [],
    count: 0,
    error: null,
    loading: false,
  },
  tempLivingOptions: {
    loading: false,
    data: {},
  },
  tempLivingOptionDetails: {
    data: {},
  },
  tempLivingRequestDetails: {
    data: {},
  },
  propertyListing: {
    data: {},
    loading: false,
    error: null,
    vars: {
      page: 1,
      pageSize: 10,
      searchKey: "",
      state: "",
      supplier: "",
      fieldName: "",
      filtersOpen: false,
      active: null,
    },
  },
  propertyDetails: {
    data: {},
    loading: false,
    error: null,
  },
  supplier: {
    data: {},
    loading: false,
    error: null,
  },
};

export const getTempLivingTransfereeList = createAsyncThunk(
  ACTION_GET_TEMP_LIVING_TRANSFEREES,
  async (filter: any) => {
    return TempLivingService.getTempLivingTransfereeList(filter);
  },
);

export const getTempLivingRequestDetails = createAsyncThunk(
  "ACTION_GET_TEMP_LIVING_REQUEST_DETAILS",
  async (requestId: string) => {
    return TempLivingService.getTempLivingRequestDetails(requestId);
  },
);

const getTempLivingRequestDetailsPending = (state: any, action: any) => {
  state.tempLivingRequestDetails.loading = true;
  state.tempLivingRequestDetails.data = {};
};

const getTempLivingRequestDetailsFullFilled = (state: any, action: any) => {
  state.tempLivingRequestDetails.loading = false;
  state.tempLivingRequestDetails.data = action.payload;
};

const getTempLivingRequestDetailsFullRejected = (state: any, action: any) => {
  state.tempLivingRequestDetails.loading = false;
  state.tempLivingRequestDetails.data = {};
  state.tempLivingRequestDetails.error = action.error
    ? action?.error?.message
    : null;
};

const getTempLivingTransfereeListPending = (state: any, action: any) => {
  state.tempLivingTransfereeList.loading = true;
  state.tempLivingTransfereeList.table = [];
};

const getTempLivingTransfereeListFulfilled = (state: any, action: any) => {
  state.tempLivingTransfereeList.loading = false;
  state.tempLivingTransfereeList.table = action.payload?.tlrList;
  state.tempLivingTransfereeList.count = action.payload.tlrCount;
};

const getTempLivingTransfereeListRejected = (state: any, action: any) => {
  state.tempLivingTransfereeList.loading = false;
  state.tempLivingTransfereeList.table = [];
  state.tempLivingTransfereeList.count = 0;
  state.tempLivingTransfereeList.error = action.error
    ? action?.error?.message
    : null;
};

export const getTempLivingOptions = createAsyncThunk(
  ACTION_GET_TEMP_LIVING_OPTIONS,
  async (requestId: string) => {
    return TempLivingService.getTempLivingOptions(requestId);
  },
);

const getTempLivingOptionsPending = (state: any, action: any) => {
  state.tempLivingOptions.loading = true;
  state.tempLivingOptions.data = [];
};

const getTempLivingOptionsFullFilled = (state: any, action: any) => {
  state.tempLivingOptions.loading = false;
  state.tempLivingOptions.data = action.payload;
};

const getTempLivingOptionsFullRejected = (state: any, action: any) => {
  state.tempLivingOptions.loading = false;
  state.tempLivingOptions.data = [];
  state.tempLivingOptions.error = action.error ? action?.error?.message : null;
};

//

export const getPropertyListing = createAsyncThunk(
  "ACTION_GET_TEMP_LIVING_PROPERTY_LISTING",
  async (data: any) => {
    return TempLivingService.getPropertyListing(data);
  },
);

const getPropertyListingPending = (state: any, action: any) => {
  state.propertyListing.loading = true;
  state.propertyListing.data = {};
};

const getPropertyListingFullFilled = (state: any, action: any) => {
  state.propertyListing.loading = false;
  state.propertyListing.data = action.payload?.data;
};

const getPropertyListingFullRejected = (state: any, action: any) => {
  state.propertyListing.loading = false;
  state.propertyListing.data = {};
  state.propertyListing.error = action.error ? action?.error?.message : null;
};

//

export const getPropertyDetails = createAsyncThunk(
  "ACTION_GET_PROPERTY_DETAILS",
  async (id: string) => {
    return TempLivingService.getPropertyDetails(id);
  },
);

const getPropertyDetailsPending = (state: any, action: any) => {
  state.propertyDetails.loading = true;
  state.propertyDetails.data = {};
};

const getPropertyDetailsFullFilled = (state: any, action: any) => {
  state.propertyDetails.loading = false;
  state.propertyDetails.data = action.payload?.data;
};

const getPropertyDetailsFullRejected = (state: any, action: any) => {
  state.propertyDetails.loading = false;
  state.propertyDetails.data = {};
  state.propertyDetails.error = action.error ? action?.error?.message : null;
};

//

export const getSupplier = createAsyncThunk(
  "ACTION_GET_SUPPLIER_LIST",
  async () => {
    return TempLivingService.getSupplier();
  },
);

const getSupplierPending = (state: any, action: any) => {
  state.supplier.loading = true;
  state.supplier.data = {};
};

const getSupplierFullFilled = (state: any, action: any) => {
  state.supplier.loading = false;
  state.supplier.data = action.payload?.data;
};

const getSupplierFullRejected = (state: any, action: any) => {
  state.supplier.loading = false;
  state.supplier.data = {};
  state.supplier.error = action.error ? action?.error?.message : null;
};

const tempLivingSlice = createSlice({
  name: "tempLiving",
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    updateVars: (state: any, action: any) => {
      state.propertyListing.vars = action.payload;
    },
    resetPropertyDetails: (state: any) => {
      state.propertyDetails.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getTempLivingTransfereeList.pending,
      getTempLivingTransfereeListPending,
    );
    builder.addCase(
      getTempLivingTransfereeList.fulfilled,
      getTempLivingTransfereeListFulfilled,
    );
    builder.addCase(
      getTempLivingTransfereeList.rejected,
      getTempLivingTransfereeListRejected,
    );
    builder.addCase(getTempLivingOptions.pending, getTempLivingOptionsPending);
    builder.addCase(
      getTempLivingOptions.fulfilled,
      getTempLivingOptionsFullFilled,
    );
    builder.addCase(
      getTempLivingOptions.rejected,
      getTempLivingOptionsFullRejected,
    );

    //

    builder.addCase(getPropertyListing.pending, getPropertyListingPending);
    builder.addCase(getPropertyListing.fulfilled, getPropertyListingFullFilled);
    builder.addCase(
      getPropertyListing.rejected,
      getPropertyListingFullRejected,
    );

    //

    builder.addCase(getPropertyDetails.pending, getPropertyDetailsPending);
    builder.addCase(getPropertyDetails.fulfilled, getPropertyDetailsFullFilled);
    builder.addCase(
      getPropertyDetails.rejected,
      getPropertyDetailsFullRejected,
    );

    //

    builder.addCase(getSupplier.pending, getSupplierPending);
    builder.addCase(getSupplier.fulfilled, getSupplierFullFilled);
    builder.addCase(getSupplier.rejected, getSupplierFullRejected);

    //tlr details

    builder.addCase(
      getTempLivingRequestDetails.pending,
      getTempLivingRequestDetailsPending,
    );
    builder.addCase(
      getTempLivingRequestDetails.fulfilled,
      getTempLivingRequestDetailsFullFilled,
    );
    builder.addCase(
      getTempLivingRequestDetails.rejected,
      getTempLivingRequestDetailsFullRejected,
    );
  },
});

export default tempLivingSlice.reducer;
export const { resetState, updateVars, resetPropertyDetails } =
  tempLivingSlice.actions;
