import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientContactService from "../../services/ClientContact/ClientContact";

export const initialState: any = {
  listing: {
    error: null,
    loading: true,
    data: {},
    vars: {
      page: 1,
      pageSize: 10,
      searchKey: "",
    },
  },
  contact: {
    error: null,
    loading: true,
    data: {},
  },
};

// ***********************************
export const getListing = createAsyncThunk(
  "ACTION_GET_CLIENT_CONTACT_LIST",
  async (data: any) => {
    return clientContactService.getListing(data);
  },
);

const getListingFullfield = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.data = action.payload?.data;
  state.listing.error = null;
};
const getListingPending = (state: any, action: any) => {
  state.listing.loading = true;
  state.listing.error = action.error ? action?.error?.message : null;
};
const getListingRejected = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.error = action.error ? action?.error?.message : "Error";
};

// ***********************************
export const getContact = createAsyncThunk(
  "ACTION_GET_CLIENT_CONTACT",
  async (id: string) => {
    return clientContactService.getContact(id);
  },
);

const getContactFullfield = (state: any, action: any) => {
  state.contact.loading = false;
  state.contact.data = action.payload?.data;
  state.contact.error = null;
};
const getContactPending = (state: any, action: any) => {
  state.contact.loading = true;
  state.contact.error = action.error ? action?.error?.message : null;
};
const getContactRejected = (state: any, action: any) => {
  state.contact.loading = false;
  state.contact.error = action.error ? action?.error?.message : "Error";
};

const clientContactSlice = createSlice({
  name: "ClientContact",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateVars: (state, action) => {
      state.listing.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    // ***********************************
    builder.addCase(getListing.fulfilled, getListingFullfield);
    builder.addCase(getListing.pending, getListingPending);
    builder.addCase(getListing.rejected, getListingRejected);

    // ***********************************
    builder.addCase(getContact.fulfilled, getContactFullfield);
    builder.addCase(getContact.pending, getContactPending);
    builder.addCase(getContact.rejected, getContactRejected);
  },
});

export default clientContactSlice.reducer;
export const { resetState, updateVars } = clientContactSlice.actions;
