import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportsState } from "../Reports/slice";
import ReportsService from "../../services/Reports/reports";
import SupplierService from "../../services/Suppliers/suppliers";
import VendorsService from "../../services/Vendors/vendors";
import { getSupplierDetails } from "../Suppliers/slice";

const initialState: any = {
  loading: false,
  vendorsData: {
    table: [],
    count: 0,
    loading: false,
  },
  vendorServices: {
    table: [],
  },
  vendorDetails: {
    data: {},
    loading: false,
  },
};

export const getAllVendors = createAsyncThunk(
  "getAllVendors",
  async (filters: any) => {
    return VendorsService.getAllVendors(filters);
  },
);

const getVendorDetailsPending = (state: any, action: any) => {
  state.vendorDetails.loading = true;
  state.vendorDetails.data = {};
};

const getVendorDetailsFullFilled = (state: any, action: any) => {
  state.vendorDetails.loading = false;
  state.vendorDetails.data = action.payload?.data.vendorDetails;
};

const getVendorDetailsRejected = (state: any, action: any) => {
  state.vendorDetails.loading = false;
  state.vendorDetails.data = {};
  state.vendorDetails.error = action.error;
};

export const getAllVendorServices = createAsyncThunk(
  "getAllVendorServices",
  async () => {
    return VendorsService.getAllVendorServices();
  },
);

export const getVendorDetails = createAsyncThunk(
  "getVendorDetails",
  async (filters: any) => {
    return VendorsService.getVendorDetails(filters);
  },
);

const slice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    resetState: (state): void => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getAllVendors.pending, (state): void => {
      state.vendorsData.loading = true;
    });
    builder.addCase(getAllVendors.fulfilled, (state, action): void => {
      state.vendorsData.table = action.payload.vendorList;
      state.vendorsData.count = 999;
      state.vendorsData.loading = false;
    });
    builder.addCase(getAllVendors.rejected, (state): void => {
      state.vendorsData.loading = false;
    });
    builder.addCase(getAllVendorServices.pending, (state): void => {
      state.vendorServices.loading = true;
    });
    builder.addCase(getAllVendorServices.fulfilled, (state, action): void => {
      state.vendorServices.table = action.payload.vendorList;
      state.vendorServices.loading = false;
    });
    builder.addCase(getAllVendorServices.rejected, (state): void => {
      state.vendorServices.loading = false;
    });

    builder.addCase(getVendorDetails.pending, getVendorDetailsPending);
    builder.addCase(getVendorDetails.fulfilled, getVendorDetailsFullFilled);
    builder.addCase(getVendorDetails.rejected, getVendorDetailsRejected);
  },
});

export const { resetState } = slice.actions;

export default slice.reducer;
