import {
  GET_EXCEPTION_LIST_URL,
  GET_RELOCATION_DETAILS_OF_TRANSFEREE,
  GET_CLIENT_CONTACT_URL,
  CREATE_EXCEPTION_URL,
  UPDATE_EXCEPTION_URL,
  GET_EXCEPTION_DETAILS_URL,
} from "../resources/constants";
import axios from "./axios-client";
import Environment from "../utils/environment";
const notLogedIn = { error: "not logged in due to no network" };

export interface exceptionResponse {
  data?: { exceptionList: exceptionType[] };
  success?: boolean;
  error?: any;
}

interface exceptionType {
  id: string;
  version: string;
  clientContactId: string;
  comments: string;
  currentPolicyAllows: string;
  dateCreated: string;
  exceptionAmount: number;
  lastUpdated: string;
  osrRecommendations: string;
  reason: string;
  requestDetails: string;
  status: string;
  statusDate: string;
  transfereeId: string;
  transferee: any;
  emailList: any;
}

export default class exceptionServices {
  static async getExceptions(filtersData: any) {
    try {
      const result: any = await axios.get(
        `${GET_EXCEPTION_LIST_URL}?${new URLSearchParams(
          filtersData,
        ).toString()}`,
      );

      return result?.data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  static async getRequestExceptionsOfTransferee(filtersData: any) {
    try {
      const result: any = await axios.get(
        `${GET_EXCEPTION_LIST_URL}?transfereeId=${filtersData?.transfereeId}&limit=${filtersData?.limit}&page=${filtersData?.page}`,
      );

      return result?.data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  static async createException(exceptionDetails: any) {
    try {
      let result: any = {};
      let isEdit = exceptionDetails.exceptionData?.isEdit;
      delete exceptionDetails.exceptionData?.isEdit;
      if (isEdit)
        result = await axios.post(UPDATE_EXCEPTION_URL, exceptionDetails);
      else result = await axios.put(CREATE_EXCEPTION_URL, exceptionDetails);
      return result;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  static async addException() {
    try {
      const result = await axios.get(GET_EXCEPTION_LIST_URL);

      return result;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  static async getRelocationDetailsOfTransferee(transfereeId: string) {
    try {
      const result: any = await axios.get(
        `${GET_RELOCATION_DETAILS_OF_TRANSFEREE}/${transfereeId}`,
      );

      return result?.data?.relocationDetails;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  static async updateExceptionOfTransferee(transfereeId: string) {
    try {
      const result: any = await axios.get(
        `${UPDATE_EXCEPTION_URL}/${transfereeId}`,
      );

      return result?.data?.exceptionList;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }
  static async getExceptionFormOfTransferee(transfereeId: string) {
    try {
      const result: any = await axios.get(
        `${GET_CLIENT_CONTACT_URL}/${transfereeId}`,
      );

      return { ...result?.data.transfereeData, ...result?.data.exceptionInfo };
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  //get exception details
  static async getException(id: string) {
    try {
      const result: any = await axios.get(`${GET_EXCEPTION_DETAILS_URL}/${id}`);
      return result?.data?.exceptionDetails;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  public static async getAllExceptions(filtersData: any) {
    return Environment.isOffline()
      ? await exceptionServices.getExceptions(filtersData)
      : notLogedIn;
  }

  public static async getRequestExceptions(transfereeId: string) {
    return Environment.isOffline()
      ? await exceptionServices.getRequestExceptionsOfTransferee(transfereeId)
      : notLogedIn;
  }

  public static async addNewException(filtersData: any) {
    return Environment.isOffline()
      ? await exceptionServices.getExceptions(filtersData)
      : notLogedIn;
  }

  public static async getRelocationDetails(id: string) {
    return Environment.isOffline()
      ? await exceptionServices.getRelocationDetailsOfTransferee(id)
      : notLogedIn;
  }

  public static async updateException(id: string) {
    return Environment.isOffline()
      ? await exceptionServices.updateExceptionOfTransferee(id)
      : notLogedIn;
  }

  public static async getExceptionForm(id: string) {
    return Environment.isOffline()
      ? await exceptionServices.getExceptionFormOfTransferee(id)
      : notLogedIn;
  }

  //create exception
  public static async createExceptionService(transfereeId: string) {
    return Environment.isOffline()
      ? await exceptionServices.createException(transfereeId)
      : notLogedIn;
  }

  //get exception details
  public static async getExceptionDetails(id: string) {
    return Environment.isOffline()
      ? await exceptionServices.getException(id)
      : notLogedIn;
  }
}
