import axios from "./axios-client";
import {
  CLEAR_NOTIFICATION,
  GET_NOTIFICATION_LIST,
} from "../resources/constants";

export default class notificationService {
  public static async getNotifications(data: any) {
    return await axios.get(
      GET_NOTIFICATION_LIST + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async clearNotification(data: any) {
    return await axios.post(CLEAR_NOTIFICATION, data);
  }
}
