import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";

import StaffServices from "../../services/Staff/Staff";

const initialState: any = {
  usersDetails: {
    table: [],
    count: 0,
    page: 1,
  },
  staffDetails: {
    table: [],
    count: 0,
    page: 1,
  },
  assignees: {
    table: [],
    count: 0,
    page: 1,
    loading: false,
  },
  loading: false,
  error: null,
};

export const getUserByRole = createAsyncThunk(
  "GET_USER_BY_ROLE",
  async (filtersData: any) => {
    const response = StaffServices.getUserByRole(filtersData);
    return response;
  },
);

export const getStaffDetails = createAsyncThunk(
  "GET_STAFF_DETAILS",
  async (filtersData: any) => {
    const response = StaffServices.getStaffDetails(filtersData);
    return response;
  },
);

export const getAssignees = createAsyncThunk(
  "GET_ASSIGNEES_LIST",
  async (filtersData: any) => {
    const response = StaffServices.getAssignees(filtersData);
    return response;
  },
);

const StaffSlice = createSlice({
  name: "Staff",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getUserByRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserByRole.fulfilled, (state, action) => {
      state.loading = false;
      state.usersDetails.table = action.payload.users;
      state.usersDetails.count = action.payload.count;
    });
    builder.addCase(getUserByRole.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    // StaffDetails
    builder.addCase(getStaffDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStaffDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.staffDetails = action.payload;
    });
    builder.addCase(getStaffDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    //get Assignees

    builder.addCase(getAssignees.pending, (state) => {
      state.loading = true;
      state.assignees.loading = true;
    });
    builder.addCase(getAssignees.fulfilled, (state, action) => {
      state.loading = false;
      state.assignees.table = action.payload.staffAssignees;
      state.assignees.loading = false;
      state.assignees.count = action.payload.staffAssigneesCount;
    });
    builder.addCase(getAssignees.rejected, (state, action) => {
      state.loading = false;
      state.assignees.loading = false;
      state.error = error(action.payload);
    });
  },
  //get
});

export const { resetState } = StaffSlice.actions;

export default StaffSlice.reducer;
