import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";
import exceptionServices from "../../services/exceptions";
import { Notify } from "../../components/Notification/Toast";

export interface ExceptionPageState {
  data?: any;
  loading: boolean;
  error?: string;
  creationCompleted: boolean;
  relocationDetails?: any;
  requestExceptions?: any;
  transfereeDetails?: any;
  exceptionDetails?: any;
  exceptions: any;
  ExceptionApproveAndDenyDetails?: any;
  updateException?: any;
}

const initialState: ExceptionPageState = {
  data: [],
  relocationDetails: {},
  loading: false,
  error: undefined,
  transfereeDetails: {},
  requestExceptions: { table: [], count: 0 },
  exceptionDetails: { data: null, error: "" },
  exceptions: { table: [], count: 0 },
  creationCompleted: false,
};

export const getAllExceptions = createAsyncThunk(
  "exceptions/fetch_all",
  async (filtersData: any) => {
    const response = exceptionServices.getAllExceptions(filtersData);

    return response;
  },
);
export const getRequestExceptions = createAsyncThunk(
  "requestExceptions",
  async (filtersData: any) => {
    const response = exceptionServices.getRequestExceptions(filtersData);

    return response;
  },
);

export const getRelocationDetails = createAsyncThunk(
  "exceptions/relocation_details",
  async (id: string) => {
    const response = exceptionServices.getRelocationDetails(id);

    return response;
  },
);

export const updateException = createAsyncThunk(
  "updateException",
  async (exceptionDetails: any) => {
    const response = exceptionServices.updateException(exceptionDetails);

    return response;
  },
);

export const getExceptionForm = createAsyncThunk(
  "getExceptionFormDetails",
  async (id: string) => {
    const response = exceptionServices.getExceptionForm(id);

    return response;
  },
);

export const getExceptionDetails = createAsyncThunk(
  "getExceptionDetails",
  async (id: string) => {
    const response = exceptionServices.getExceptionDetails(id);

    return response;
  },
);

export const createException = createAsyncThunk(
  "createException",
  async (exceptionDetails: any) => {
    const response = exceptionServices.createException(exceptionDetails);

    return response;
  },
);

//create a slice for update exception

const exceptionSlice = createSlice({
  name: "exceptions",
  initialState,
  reducers: {
    clearCreation: (state, action) => {
      state.creationCompleted = action.payload;
    },
    clearFormData: (state) => {
      state.creationCompleted = false;
      state.exceptionDetails = {};
    },
  },
  extraReducers(builder): void {
    //get all exceptions
    builder.addCase(getAllExceptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllExceptions.fulfilled, (state, action) => {
      state.loading = false;

      state.exceptions.table = action.payload.exceptionList;
      state.exceptions.count = action.payload.exceptionCount;
    });
    builder.addCase(getAllExceptions.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });

    //get request exceptions
    builder.addCase(getRequestExceptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRequestExceptions.fulfilled, (state, action) => {
      state.loading = false;
      state.requestExceptions.table = action.payload.exceptionList;
      state.requestExceptions.count = action.payload.exceptionCount;
    });
    builder.addCase(getRequestExceptions.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });

    //get exception form
    builder.addCase(getExceptionForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExceptionForm.fulfilled, (state, action) => {
      state.loading = false;
      state.transfereeDetails = action.payload;
    });
    builder.addCase(getExceptionForm.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
    //create exception
    builder.addCase(createException.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createException.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      Notify.success("Exception Created Successfully");
      state.creationCompleted = true;
    });

    builder.addCase(createException.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
    //update exception

    builder.addCase(updateException.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateException.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      Notify.success("Exception Updated Successfully");
    });

    builder.addCase(updateException.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });

    //get exception details
    builder.addCase(getExceptionDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getExceptionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.exceptionDetails.data = {
        ...action.payload.exception,
        ...action.payload.exceptionInfo,
        assignedClientContact: action.payload.assignedClientContact,
        assignedAccountManager: action.payload.assignedAccountManager,
        exceptionRequestEmails: action.payload.exceptionRequestEmails,
      };
    });

    builder.addCase(getExceptionDetails.rejected, (state, action) => {
      state.loading = false;
      state.exceptionDetails.error = action?.error?.message;
    });
  },
});

export const { clearCreation, clearFormData } = exceptionSlice.actions;

export default exceptionSlice.reducer;
