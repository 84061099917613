import {
  GET_SUPPLIER_DASHBOARD_STATS,
  TEMP_LIVING_TRANSFEREE_LIST_URL,
  SUPPLIER_CITY_LIST,
  VENDOR_MOVE_HELP_CARD_TRACKING,
} from "../../resources/constants";
import axios from "../axios-client";

export default class SupplierPortalService {
  public static async supplierStats() {
    return await axios.get(GET_SUPPLIER_DASHBOARD_STATS);
  }

  public static async getCityList() {
    return await axios.get(SUPPLIER_CITY_LIST);
  }

  public static async supplierRequestList(data: any) {
    return await axios.post(TEMP_LIVING_TRANSFEREE_LIST_URL, data);
  }

  public static async moveHelpCardTracking(data: any) {
    return await axios.post(VENDOR_MOVE_HELP_CARD_TRACKING, data);
  }
}
