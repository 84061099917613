import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportsState } from "../Reports/slice";
import ReportsService from "../../services/Reports/reports";
import SupplierService from "../../services/Suppliers/suppliers";
import { getTempLivingTransfereeList } from "../TempLiving/slice";
import TempLivingService from "../../services/TempLiving/tempLiving";

const initialState: any = {
  suppliersData: {
    table: [],
    count: 0,
    status: [],
    loading: false,
  },
  supplierDetails: {
    loading: false,
    data: {},
  },
  propertyListing: {
    table: [],
    count: 0,
  },
};

export const getAllSuppliers = createAsyncThunk(
  "getAllSuppliers",
  async (filters: any) => {
    return SupplierService.getAllSuppliers(filters);
  },
);

export const getSupplierDetails = createAsyncThunk(
  "getSupplierDetails",
  async (filters: any) => {
    return SupplierService.getSupplierDetails(filters);
  },
);

export const deleteSupplier = createAsyncThunk(
  "deleteSupplier",
  async (filters: any) => {
    return SupplierService.deleteSupplier(filters);
  },
);

export const getPropertyListing = createAsyncThunk(
  "ACTION_GET_TEMP_LIVING_PROPERTY_LISTING",
  async (data: any) => {
    return TempLivingService.getPropertyListing(data);
  },
);

const getAllSuppliersPending = (state: any, action: any) => {
  state.suppliersData.loading = true;
  state.suppliersData.table = [];
};

const getAllSuppliersFullFilled = (state: any, action: any) => {
  state.suppliersData.loading = false;
  state.suppliersData.table = action.payload.suppliers;
  state.suppliersData.count = action.payload.count;
};

const getAllSuppliersFullRejected = (state: any, action: any) => {
  state.suppliersData.loading = false;
  state.suppliersData.table = [];
  state.suppliersData.count = 0;
  state.suppliersData.error = action.error;
};

const getSupplierDetailsPending = (state: any, action: any) => {
  state.supplierDetails.loading = true;
  state.supplierDetails.data = {};
};

const getSupplierDetailsFullFilled = (state: any, action: any) => {
  state.supplierDetails.loading = false;
  state.supplierDetails.data = action.payload?.data.supplierInfo;
};

const getSupplierDetailsFullRejected = (state: any, action: any) => {
  state.supplierDetails.loading = false;
  state.supplierDetails.data = {};
  state.supplierDetails.error = action.error;
};

const getPropertyListingPending = (state: any, action: any) => {
  state.propertyListing.loading = true;
  state.propertyListing.data = {};
};

const getPropertyListingFullFilled = (state: any, action: any) => {
  state.propertyListing.loading = false;
  state.propertyListing.table = action.payload?.data?.propertyList;
  state.propertyListing.count = action.payload?.data?.propertyCount;
};

const getPropertyListingFullRejected = (state: any, action: any) => {
  state.propertyListing.loading = false;
  state.propertyListing.data = {};
  state.tempLivingOptions.error = action.error ? action?.error?.message : null;
};

const slice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getAllSuppliers.pending, getAllSuppliersPending);
    builder.addCase(getAllSuppliers.fulfilled, getAllSuppliersFullFilled);
    builder.addCase(getAllSuppliers.rejected, getAllSuppliersFullRejected);
    builder.addCase(getSupplierDetails.pending, getSupplierDetailsPending);
    builder.addCase(getSupplierDetails.fulfilled, getSupplierDetailsFullFilled);
    builder.addCase(
      getSupplierDetails.rejected,
      getSupplierDetailsFullRejected,
    );
    builder.addCase(getPropertyListing.pending, getPropertyListingPending);
    builder.addCase(getPropertyListing.fulfilled, getPropertyListingFullFilled);
    builder.addCase(
      getPropertyListing.rejected,
      getPropertyListingFullRejected,
    );
  },
});

export const { resetState } = slice.actions;

export default slice.reducer;
