import React from "react";
import { Menu } from "antd";
import { MenuProps } from "antd/es/menu";

interface IMenuProps extends MenuProps {
  myProp?: string;
  onClick?: (e: any) => void;
}

const CustomMenu: React.FC<IMenuProps> = ({ ...props }) => {
  return (
    <Menu
      onClick={props.onClick}
      {...props}
      className="MenuSingleItem"
      items={props?.items || []}
    ></Menu>
  );
};

export default CustomMenu;
