import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";

import clientServices from "../../services/Client/client";

const initialState: any = {
  clientDetails: {
    table: [],
    count: 0,
    page: 1,
  },
  client: {
    data: {},
    error: null,
    loading: false,
  },
  logs: {
    data: {},
    error: null,
    loading: false,
  },
  loading: false,
  error: null,
};

export const getAllClients = createAsyncThunk(
  "GET_ALL_CLIENTS",
  async (filtersData: any) => {
    const response = clientServices.getClients(filtersData);
    return response;
  },
);

export const getAllClientStats = createAsyncThunk(
  "GET_CLIENT_CONFIGURATION_STATS",
  async (filtersData: any) => {
    const response = clientServices.getClientConfigurationStats(filtersData);
    return response;
  },
);
export const getClient = createAsyncThunk(
  "GET_CLIENT_BY_ID",
  async (id: string) => {
    const response = clientServices.getClientDetails(id);
    return response;
  },
);

export const getClientLogs = createAsyncThunk(
  "GET_CLIENT_LOGS",
  async (data: any) => {
    const response = clientServices.getClientLogs(data);
    return response;
  },
);

//create a slice for Client
const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    //get all Clients
    builder.addCase(getAllClients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllClients.fulfilled, (state, action: any) => {
      state.loading = false;
      state.clientDetails.table = action.payload?.data.clients;
      state.clientDetails.count = action.payload?.data.count;
    });
    builder.addCase(getAllClients.rejected, (state, action: any) => {
      state.loading = false;
      state.error = error(action.payload?.data);
    });

    //get all Clients stats
    builder.addCase(getAllClientStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllClientStats.fulfilled, (state, action: any) => {
      state.loading = false;
      state.clientDetails.table = action.payload?.data.clientStats;
      state.clientDetails.count = action.payload?.data.clientCount;
    });
    builder.addCase(getAllClientStats.rejected, (state, action: any) => {
      state.loading = false;
      state.error = error(action.payload?.data);
    });

    //get Client by id
    builder.addCase(getClient.pending, (state) => {
      state.client.loading = true;
    });
    builder.addCase(getClient.fulfilled, (state, action: any) => {
      state.client.loading = false;
      state.client.data = action.payload?.data;
    });
    builder.addCase(getClient.rejected, (state, action: any) => {
      state.client.loading = false;
      state.client.error = error(action.payload);
    });

    //get Client logs
    builder.addCase(getClientLogs.pending, (state) => {
      state.logs.loading = true;
    });
    builder.addCase(getClientLogs.fulfilled, (state, action: any) => {
      state.logs.loading = false;
      state.logs.data = action.payload?.data;
    });
    builder.addCase(getClientLogs.rejected, (state, action: any) => {
      state.logs.loading = false;
      state.logs.error = error(action.payload);
    });
  },
});

export default ClientSlice.reducer;
