import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_DIVISION_LISTING } from "../../Actions/actions";
import divisionsServices from "../../services/Divisions/divisions";
import error from "../../utils/error";

export interface Divisions {
  divisions?: any;
  loading: boolean;
  error?: string;
}

const initialState: Divisions = {
  divisions: [],
  loading: false,
  error: undefined,
};

export const getAllDivisions = createAsyncThunk(
  ACTION_DIVISION_LISTING,
  async (params: {
    clientId: string;
    divisionId: string;
    direction: string;
  }) => {
    return divisionsServices.getAllDivisions(
      params.clientId,
      params.divisionId,
      params.direction,
    );
  },
);

const divisionSlice = createSlice({
  name: "divisions",
  initialState,
  reducers: {},
  extraReducers: function (builder): void {
    //get all divisions
    builder.addCase(getAllDivisions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDivisions.fulfilled, (state, action) => {
      state.loading = false;
      state.divisions = action.payload || [];
    });
    builder.addCase(getAllDivisions.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default divisionSlice.reducer;
