export const logo = `${process.env.REACT_APP_CDN_URL}/images/logo.png`;
export const messageBox = `${process.env.REACT_APP_CDN_URL}/svgs/messageBox.svg`;
export const reloLogo = `${process.env.REACT_APP_CDN_URL}/svgs/reloLogo.svg`;

export const GET_USER_INFO_URL = `user/profile/info`;
export const GET_USER_BY_ROLE = `user/get-all-usersByRole`;

export const SEND_WELCOME_EMAIL = `user/welcome-user`;
export const SEND_WELCOME_EMAIL_SUPPLIER = `user/welcome-supplier-users`;
export const GET_FORGOT_PASSWORD_URL = `user/auth/forgot-password`;
export const GET_FORGOT_USERNAME_URL = `user/auth/forgot-user-name`;
export const signUpUrl = `user/auth/signUp`;
export const loginUrl = `user/auth/login`;
export const REFRESH_TOKEN_URL = `user/auth/refreshToken`;

export const CURRENCY = "$";
export const DEFAULT_COUNTRY_ID = "233";
export const DEFAULT_COUNTRY_ID2 = "39";
export const TOKEN_EXCHANGE = "user/auth/exchange-token";
export const ADHOC_EXPORT_ASSIGNMENT = "adhoc-policy/export-adhoc-benefits";
export const UPDATE_USER_INFO = "transferee/update-user-data";

//Dashboard URL's
export const countriesUrl = "countries";
export const ytdSpends = "dashboard/total-ytd-spend";
export const ytdAuthorisations = "dashboard/total-ytd-authorisations";
export const ytdActiveFiles = "dashboard/total-active-file-count";
export const auhorisationsByPolicy = "dashboard/authorisations-by-policy";
export const spendsByPolicy = "dashboard/spends-by-policy";
export const ExportData = "dashboard/export-dashboard-data";
export const exceptionsByPolicy = "dashboard/exceptions-by-policy";
export const mapsData = "dashboard/maps-data";
export const upcomingVisaExp = "dashboard/upcoming-visa-expiry";
export const GET_SERVICE_BY_POLICY = "dashboard/services-by-policy";
export const CHECK_FAVOURITE_NAME = "favorites/favorite-name-check";
export const CREATE_FAVOURITE = "favorites/create-favorite";
export const GET_FAVOURITE_LIST = "favorites/favorites-list";

//Divisions URL's
export const getDivisions = "divisions/get-authorized-divisions";
export const GET_DIVISION_VIEW = "divisions/get-division-details";
export const GET_POLICY_LIST = "policy/get-policies-list";
export const GET_CONSULTANT_LIST = "/consultant/consultants";
export const GET_DIVISION_FORM = "divisions/get-division-form";
export const GET_INIT_CONFIG = "config/get-division-config";
export const CREATE_DIVISION = "divisions/create-division";
export const UPDATE_DIVISION = "divisions/update-division";
export const GET_TOP_DIVISION = "client-division/get-client-division-details";
export const UPDATE_TOP_DIVISION = "client-division/update-division";
export const GET_TOP_DIVISION_FORM = "client-division/get-division-form";
export const GET_AVAIL_POLICY = "policy/get-available-policies";
export const UPDATE_ASSIGN_POLICY = "policy/assign-policies";

export const GET_EXCEPTION_LIST_URL = "exception/get-exception-list";
export const ADD_OR_UPDATE_EXCEPTION_URL = "exception/create-exception";
export const GET_RELOCATION_DETAILS_OF_TRANSFEREE =
  "transferee/get-relocation-details";
export const GET_TRANSFEREE_LIST_URL = "transferee/get-transferees";
export const GET_TRANSFEREE_HISTORY_URL = "history/get-transferee-history";
export const GET_CLIENT_CONTACT_URL = "exception/get-exception-form";
export const GET_TRANSFEREE_PROFILE_URL = "transferee/get-transferee";
export const GET_DIVISIONS_URL = "divisions/get-divisions-list";

export const GET_AUTHORIZED_DIVISIONS_URL =
  "divisions/get-authorized-divisions";
export const GET_COST_ESTIMATES_URL = "/estimator/estimates";
export const CREATE_EXCEPTION_URL = "exception/create-exception";
export const UPDATE_EXCEPTION_URL = "exception/update-exception";
export const GET_EXCEPTION_DETAILS_URL = "exception/get-exception-details";
export const GET_COST_ESTIMATES_VIEW_URL = "estimator/get-estimate";
export const GET_COUNTRY_URL = "geography/get-countries";
export const GET_STATE_URL = "geography/get-states";
export const GET_CITY_URL = "geography/get-cities";
export const GET_COST_ESTIMATES_SHIPPING_TOTAL = "estimator/calSHGTotal";
export const GET_COST_ESTIMATES_DEFAULT_CONFIG =
  "cost-estimator-config/get-default-config";
export const GET_COST_ESTIMATES_APPROVERS = "user/get-all-clientContacts";
export const CREATE_COST_ESTIMATES_APPROVERS = "approval/create-approval";
export const CREATE_COST_ESTIMATES_PDF = "estimator/generate-pdf";
export const CREATE_COST_ESTIMATES = "estimator/create-estimate";
export const UPDATE_COST_ESTIMATES = "estimator/update-estimate";
export const COPY_COST_ESTIMATES = "estimator/copy-estimate-by-id";
// export const GET_CONSULTANT_LIST_URL = "consultant/get-consultants";
export const DELETE_APPROVER = "approval/delete-approval";
export const UPDATE_APPROVAL = "approval/update-approval";
export const REQUEST_COST_ESTIMATE = "transferee/request-cost-estimate";
export const LINK_COST_ESTIMATE = "transferee/link-cost-estimate";
export const GET_ALL_TRANSFEREES = "transferee/get-all-transferees";

//client list url
export const GET_CLIENT_LIST_URL = "client/clients";
export const GET_CLIENT_CONFIGURATION_STATS = "client/get-client-stats";

export const GET_CLIENT_BY_ID = "client/get-client-details";
export const CREATE_CLIENT = "client/create-client";
export const UPDATE_CLIENT = "client/update-client";
export const DELETE_CLIENT = "client/delete-client";
export const DELETE_CLIENT_LOGO = "client/delete-client-logo";
export const GET_CLIENT_FORM_DETAILS = "client/get-client-form";
export const CONTACT_SUPPORT = "help/report-issue";

// Banking Information URL's
export const GET_BANKING_INFORMATION_LIST = "bankinfo/bank-info-list";
export const GET_BANKING_INFORMATION = "bankinfo/get-bank-info";
export const CREATE_BANKING_ACCOUNT = "bankinfo/create-bank-info";
export const UPDATE_BANKING_ACCOUNT = "bankinfo/update-bank-info";
export const DELETE_BANKING_ACCOUNT = "bankinfo/delete-bank-info";
export const GET_BANK_INFO_UPDATES_LIST = "bankinfo/get-bank-info-updates";
export const GET_BANK_INFO = "bankinfo/get-bank-info";
export const BANK_INFO_LIST = "bankinfo/bank-info-list";
export const SET_DISMISS_FOR_IDS = "bankinfo/set-dismissed-for-ids";

// Timeline API URL's
export const CREATE_RELO_EVENT_URL = "timeline/create-relo-event";
export const UPDATE_RELO_EVENT_URL = "timeline/update-relo-event";
export const GET_RELO_EVENT_URL = "timeline/get-relo-event";
export const GET_TRANSFEREE_FROM_MILO_URL = "timeLine/get-transferee-from-milo";
export const GET_RELO_EVENT_LIST_URL = "timeline/list-relo-events";
export const GET_MILO_EVENT_LIST_URL = "timeline/milo-events";
export const GET_EVENT_DETAILS_URL = "timeline/events";
export const GET_TIMELINE_MANAGEMENT_URL =
  "timeline-management/get-timeline-object";
export const CREATE_TIMELINE_MANAGEMENT_URL =
  "timeline-management/create-timeline-object";
export const UPDATE_TIMELINE_MANAGEMENT_URL =
  "timeline-management/update-timeline-object";

export const GET_MOVE_IN_DATE_URL = "transferee/get-transferee-move-in-date";
export const UPDATE_MOVE_IN_DATE_URL = "transferee/update-move-in-date";

// File Share URL's
export const GET_FILE_SHARE_FOLDERS = "dropbox/get-folder-list";
export const CREATE_FILE_SHARE_FOLDER = "dropbox/create-dropbox";
export const UPDATE_FILE_SHARE_FOLDER = "dropbox/update-dropbox";
export const GET_DROPBOX_DETAILS = "dropbox/get-dropbox";
export const GET_FILE_LIST = "dropbox/get-file-list";
export const UPLOAD_FILES_DROPBOX = "dropbox/upload-file";
export const DELETE_FILE_DROPBOX = "dropbox/delete-file";
export const GET_ALL_REPORTS_URL = "reports/get-all-reports";
export const GET_CLOSED_REPORTS_URL = "reports/get-closed-report";
export const GET_INITIATION_REPORTS_URL = "reports/get-intiation-report";
export const GET_EXCEPTIONS_REPORT_URL = "reports/get-exceptions-report";
export const GET_FAV_DASHBOARD_DATA = "dashboard/get-fav-dashboard-data";
export const GET_CLIENT_EXPENSE_REPORT_URL =
  "reports/get-client-expense-report";
export const GET_CLIENT_EXPENSE_BY_CATEGORY_URL =
  "reports/get-expense-category-report";

export const GET_TLR_REPORTS = "tlr/fetch-tlr-report-data";
export const GET_TLO_REPORTS = "tlo/fetch-tlo-report-data";

export const GET_SUBMITTED_EXPENSE_REPORT = "reports/get-submitted-expenses";
export const GET_EXCEPTION_REQUEST_REPORT = "reports/get-exception-req-report";

export const VENDOR_TRACKING_MOVE_HELP = "vendor-tracking/fetch-tracking-data";

export const SEND_EXPENSE_EMAIL = "submittedExpense/send-internal-email";
export const UPDATE_EXPENSE_PROCESS =
  "submittedExpense/process-submitted-expenses";
export const SEND_CUSTOM_EMAIL = "custom-email/send-custom-email";

export const GET_POLICY_BY_DIVISION_URL = "policy/get-policies-list";
export const GET_DIVISION_CONFIG_URL = "config/get-division-config";
export const DIVISION_DELETE = "divisions/delete-division";
export const DIVISION_TOGGLE = "divisions/toggle-division-visibility";

export const GET_STATUS_LIST = "transferee/get-status-list";
export const CREATE_EMPLOYEE = "transferee/create-transferee";

export const CREATE_MILO_TRANSFEREE = "transferee/create-milo-transferee";
export const UPDATE_EMPLOYEE = "transferee/update-transferee";

export const DOWNLOAD_DROPBOX_FILE = "dropbox/download-file";

export const TEMP_LIVING_TRANSFEREE_LIST_URL = "tlr/get-tlr-list";

export const TEMP_LIVING_OPTION_DETAILS_URL = "tlo/get-option-details";

export const TEMP_LIVING_OPTIONS_URL = "tlr/get-tlr";

export const DELETE_TLO = "tlo/delete-tlo";
export const UPDATE_TLO_STATUS = "tlo/change-tlo-status";
export const UPDATE_TLO_VISIBILITY = "tlo/toggle-hide-tlo";
export const CREATE_TLO = "tlo/create-tlo";
export const UPDATE_TLO = "tlo/update-tlo";
export const SET_NO_OPTIONS = "no-tlo/no-options-available";

export const GET_FINANCIAL_VIEW = "program/get-financials";
export const GET_FINANCIALS_LIST = "program/get-financials-list";

export const UPDATE_TLO_PREFERENCE = "tlo/update-tlo-preference";

// Expense Submission URL's
export const GET_EXPENSE_SUBMISSION_LISTING =
  "submittedExpense/get-all-submitted-expenses";
export const GET_EXPENSE_SUBMISSION_FILTERS =
  "submittedExpense/get-all-filters";
export const GET_EXPENSE_SUBMISSION_BY_ID =
  "submittedExpense/get-submitted-expense-by-id";
export const GET_EXPENSE_SUBMISSION_FOLDER_LIST =
  "expense/get-expense-folder-list";
export const CREATE_EXPENSE_SUBMISSION_FOLDER = "expense/create-expense-folder";
export const UPDATE_EXPENSE_SUBMISSION_FOLDER = "expense/update-expense-folder";
export const GET_EXPENSE_SUBMISSION_CATEGORY =
  "expenseCategory/expense-category-list";
export const GET_EXPENSE_SUBMISSION_TYPE =
  "expenseType/expense-type-by-category";
export const CREATE_EXPENSE_SUBMISSION = "submittedExpense/submit-expense";
export const DELETE_EXPENSE_SUBMISSION =
  "submittedExpense/delete-submitted-expense";
export const DELETE_EXPENSE_SUBMISSION_FILE =
  "submittedExpense/delete-submitted-expense-file";
export const UPDATE_EXPENSE_SUBMISSION =
  "submittedExpense/update-submitted-expense";
export const UPLOAD_EXPENSE_SUBMISSION_FILE =
  "submittedExpense/upload-submitted-expense-file";
export const GET_BENEFIT_LIMITS = "submittedExpense/benefit-limits";
export const ASSIGN_EXPENSE = "submittedExpense/assign-finance-user";

// Client Contact URL's
export const CREATE_CLIENT_CONTACT = "client-contact/create-client-contact";
export const UPDATE_CLIENT_CONTACT = "client-contact/update-client-contact";
export const DEACTIVATE_CLIENT_CONTACT =
  "client-contact/deactivate-client-contact";
export const REACTIVATE_CLIENT_CONTACT =
  "client-contact/reactivate-client-contact";
export const GET_CLIENT_CONTACT = "client-contact/get-client-contact";
export const GET_CLIENT_CONTACT_LISTING =
  "client-contact/get-client-contact-list";
export const GET_CLIENT_SUPPORT = "client/get-support-team";
// client member group urls
export const GET_CLIENT_MEMBER_LISTING = "cmgroup/get-cmgroup-list";
export const GET_CLIENT_MEMBER_VIEW = "cmgroup/get-cmgroup";
export const GET_CLIENT_MEMBER_ROLES = "cmgroup/get-cmrole-list";
export const CREATE_CLIENT_MEMBER = "cmgroup/create-cmgroup";
export const UPDATE_CLIENT_MEMBER = "cmgroup/update-cmgroup";

// CRI group urls
export const GET_CRI_GROUP_LISTING = "crigroup/get-crigroup-list";
export const GET_CRI_GROUP_VIEW = "crigroup/get-crigroup";
export const GET_CRI_GROUP_ROLES = "crigroup/get-omrole-list";
export const CREATE_CRI_GROUP = "crigroup/create-crigroup";
export const UPDATE_CRI_GROUP = "crigroup/update-crigroup";

// temp living property
export const GET_PROPERTY_LISTING = "property/get-property-list";

export const GET_PROPERTY_DETAILS = "property/get-property-details";

// cost estimate config
export const GET_COST_ESTIMATE_CONFIGS =
  "/cost-estimator-config/get-config-list";

export const GET_COST_ESTIMATE_CONFIG_BY_ID =
  "/cost-estimator-config/get-config";

export const DELETE_COST_ESTIMATE_CONFIG =
  "/cost-estimator-config/delete-config";

export const CREATE_COST_ESTIMATE_CONFIG =
  "/cost-estimator-config/create-config";

export const UPDATE_COST_ESTIMATE_CONFIG =
  "/cost-estimator-config/update-config";

export const SUPPLIERS_LISTING_URL = "supplier/get-supplier-list";

export const VENDOR_SERVICES_URL = "vendor/list-vendor-services";

export const VENDORS_URL = "vendor/list-vendors";

export const CREATE_SUPPLIER_URL = "supplier/create-supplier";

export const UPDATE_SUPPLIER_URL = "supplier/update-supplier";

export const GET_SUPPLIER_URL = "supplier/get-supplier-details";
export const DELETE_SUPPLIER = "supplier/delete-supplier";

export const CREATE_VENDOR_URL = "vendor/create-vendor";

export const UPDATE_VENDOR_URL = "vendor/update-vendor";

export const GET_VENDOR_URL = "vendor/get-vendor";

//staff

export const GET_STAFF_LISTING = "staff/get-staff-list";
export const GET_STAFF_DETAILS = "staff/get-staff-details";
export const CREATE_STAFF_URL = "staff/create-staff";
export const UPDATE_STAFF_URL = "staff/update-staff";
export const UPDATE_USER_PROFILE = "user/update-user-profile";
export const DELETE_STAFF_URL = "staff/delete-staff";
export const GET_ASSIGNEES_LIST = "staff/get-assignees";
export const TOGGLE_STAFF_VISIBILITY = "staff/toggle-staff-status";

export const DELETE_PROFILE_PICTURE = "user/delete-profile-picture";
export const DELETE_STAFF_PROFILE_PICTURE =
  "staff/delete-staff-profile-picture";

export const DELETE_VENDOR = "vendor/delete-vendor";
export const GET_SUPPLIER_LIST = "supplier/get-supplier-list";
export const CREATE_PROPERY = "property/create-property";
export const UPDATE_PROPERTY = "property/update-property";
export const DELETE_PROPERTY = "property/delete-property";
export const DELETE_PROPERTY_IMAGE = "property/delete-property-image";
export const UPLOAD_PROPERTY_IMAGES = "property/upload-property-image";
export const UPDATE_PROPERTY_IMAGES = "property/update-property-image";
export const UPDATE_PROPERTY_VISIBILITY = "property/toggle-property-visibility";
export const GET_EXPENSE_CATEGORY = "expenseCategory/expense-category-list";
export const MANAGE_EXPENSE_CATEGORY =
  "expenseCategory/expense-category-types-list";

export const CREATE_EXPENSE_CATEGORY =
  "expenseCategory/create-expense-category";

export const UPDATE_EXPENSE_CATEGORY =
  "expenseCategory/update-expense-category";

export const DELETE_EXPENSE_CATEGORY =
  "expenseCategory/delete-expense-category";

export const GET_EXPENSE_CATEGORY_DETAILS =
  "expenseCategory/expense-category-by-id";

export const GET_EXPENSE_TYPE_DETAILS = "expenseType/expense-type-by-id";

export const CREATE_EXPENSE_TYPE = "expenseType/create-expense-type";

export const UPDATE_EXPENSE_TYPE = "expenseType/update-expense-type";

export const CREATE_TLR = "tlr/create-tlr";
export const UPDATE_TLR = "tlr/update-tlr";
export const DELETE_TLR = "tlr/delete-tlr";
export const GET_TLR_DETAILS = "tlr/get-tlr";

//
export const GET_ALL_POLICY_LIST = "policy/get-all-policies";
export const GET_POLICY_VIEW = "policy/get-policy";
export const CREATE_POLICY = "policy/create-policy";
export const UPDATE_POLICY = "policy/update-policy";
export const DELETE_POLICY = "policy/delete-policy";
export const GET_POLICY_CONFIG = "generalConfig/general-config-by-id";
export const CREATE_POLICY_CONFIG = "generalConfig/create-general-config";
export const UPDATE_POLICY_CONFIG = "generalConfig/update-general-config";
export const GET_POLICY_REM_LIST =
  "categoryTypeExpConfig/rem-cattype-exp-conf-by-policy";
export const DELETE_EXPENSE_TYPE = "expenseType/delete-expense-type";
export const GET_POLICY_CATEGORY_CONFIG =
  "categoryTypeExpConfig/category-type-exp-conf-by-policy";
export const CREATE_POLICY_CATEGORY_CONFIG =
  "categoryTypeExpConfig/create-category-type-exp-conf";
export const UPDATE_POLICY_CATEGORY_CONFIG =
  "categoryTypeExpConfig/update-category-type-exp-conf";
export const DELETE_POLICY_CATEGORY_CONFIG =
  "categoryTypeExpConfig/delete-category-type-exp-conf";
export const GET_POLICY_LIMIT_GROUP = "limitGroup/limit-group-by-policy";
export const CREATE_POLICY_LIMIT_GROUP = "limitGroup/limit-group-create";
export const UPDATE_POLICY_LIMIT_GROUP = "limitGroup/update-limit-group";
export const DELETE_POLICY_LIMIT_GROUP = "limitGroup/delete-limit-group";
export const COPY_POLICY = "policy/copy-policy-by-id";

export const SEARCH_MILO_TRANSFEREE = "transferee/search-milo-transferee";

export const GET_MILO_TRANSFEREE_DETAILS =
  "transferee/get-milo-transferee-details";

// Whats News URL's
export const GET_WHATS_NEW = "news-feed/list-news-feed";
export const CREATE_WHATS_NEW = "news-feed/create-news-feed";
export const UPDATE_WHATS_NEW = "news-feed/update-news-feed";
export const DELETE_WHATS_NEW = "news-feed/delete-news-feed";
export const TOGGLE_WHATS_NEW = "news-feed/toggle-news-feed-item";
export const SET_NEWS_SEQUENCE = "news-feed/set-news-feed-sequence";

// AdHoc Policy
export const GET_ADHOC_TEMPLATE = "adhoc-policy/get-all-benefits";
export const GET_ADHOC = "adhoc-policy/get-benefits";
export const UPDATE_ADHOC = "adhoc-policy/update-benefits";

// Import Export
export const GET_MILO_IMPORT = "import/import-file-numbers";
export const GET_MILO_TRANSFEREE_EXPORT = "export/export-init-transferees";

export const UPDATE_TRANSFEREE_STATUS = "transferee/update-transferee-status";
export const SEND_LOGGER = "loggers/log-message";
export const EXPENSE_BY_CATEGORY = "reports/email-or-dwnld-exp-cat-report";
export const TRL_REVIEW_EMAIL = "tlr/send-tlo-review-email";

export const EXPENSE_SUBMISSION_PDF =
  "https://cdn.onesourcerelocation.com/static/help/Expense_SubmissionGuide2023.pdf";
export const TRANSFEREE_AUTH_PDF =
  "https://cdn.onesourcerelocation.com/static/help/Transferee_Auth_Guide2023.pdf";
export const EXPORT_BANK_INFO = "bankinfo/download-bank-report";
export const DELETE_TRANSFEREE = "transferee/delete-transferee";

// self guided portal
export const GET_BUDGET = "sgportal/get-budget";
export const CREATE_BUDGET = "sgportal/create-budget";
export const SET_BUDGET_HELP = "sgportal/set-budget-help";
export const GET_EXPENSE_LIST = "sgportal/get-expense-list";
export const CREATE_SG_EXPENSE = "sgportal/create-sgls-expense";
export const DELETE_SG_EXPENSE = "sgportal/delete-sgls-expense";
export const SEND_GET_STARTED_EMAIL = "sgportal/get-started";

// expense authorization
export const GET_EXPENSE_AUTHORIZATION_LIST =
  "authorizedExpense/get-non-hidden-auth-expens";
export const GET_MILO_EXPENSE_LIST = "authorizedExpense/get-all-milo-expenses";
export const GET_RESOLVED_EXPENSE_LIST =
  "authorizedExpense/get-all-resolved-expenses";
export const CREATE_AUTORIZATION =
  "authorizedExpense/create-authorized-expense";
export const UPDATE_AUTORIZATION =
  "authorizedExpense/update-authorized-expense";
export const DELETE_AUTORIZATION =
  "authorizedExpense/delete-authorized-expense";
export const MATCH_AUTORIZATION = "authorizedExpense/match-all-expenses";
export const UNMATCH_AUTORIZATION = "authorizedExpense/unmatch-all-expenses";

// supplier portal
export const GET_SUPPLIER_REQUEST_LIST = "supplier/get-tlr-list";
export const GET_SUPPLIER_DASHBOARD_STATS = "supplier/stats";
export const SUPPLIER_CITY_LIST = "supplier/get-city-list";
export const SECONDARY_TLO_PREFERENCE =
  "/tlr/send-secondary-tlo-preference-email";
export const VENDOR_MOVE_HELP_CARD_TRACKING =
  "vendor-tracking/track-transferee-activity";

// pii acknowledgement
export const SET_PII_ACKNOWLEDGEMENT = "transferee/pii-acknowledgement";
export const CRI_EMAIL_NOTIFICATION = "transferee/notify-cri-staff";
export const SUPPLIER_USER_TOGGLE = "supplier/toggle-supplier-contact-status";

// notification
export const GET_EVENT_NOTIFICATIONS = "notifications/events";
export const GET_NOTIFICATION_LIST = "notifications/get-notifications-list";
export const GET_EVENT_NOTIFICATION_TOKEN =
  "user/auth/generate-notifications-token";
export const CLEAR_NOTIFICATION = "notifications/dismiss-notifications";
export const CLIENT_CHANGE_LOG = "client/client-change-log";
export const POLICY_CHANGE_LOG = "policy/policy-change-log";

export const CREATE_CUSTOM_NOTIFICATION =
  "notifications/create-custom-notification";
export const UPDATE_CUSTOM_NOTIFICATION =
  "notifications/update-custom-notification";
export const TOGGLE_CUSTOM_NOTIFICATION =
  "notifications/toggle-custom-notification";
export const GET_CUSTOM_NOTIFICATION = "notifications/get-custom-notification";
export const GET_CUSTOM_NOTIFICATION_LIST =
  "notifications/get-custom-notifications-list";
export const GET_CM_GROUP_BY_CLIENT = "cmgroup/get-cmgroup-list-by-clients";
export const GET_POLICY_BY_CLIENT = "policy/get-policies-list-by-clients";
