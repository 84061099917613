import axios from "./axios-client";
import {
  GET_MILO_IMPORT,
  GET_MILO_TRANSFEREE_EXPORT,
} from "../resources/constants";

export default class importExportService {
  public static async syncMilo() {
    return await await axios.get(GET_MILO_IMPORT);
  }

  public static async getMiloTransfereeCSV() {
    const headers = {
      responseType: "blob",
    };
    return await await axios.get(GET_MILO_TRANSFEREE_EXPORT, headers);
  }
}
