import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import timelineService from "../../services/Timeline/timeline";
import {
  ACTION_GET_DIVISION_FORM,
  ACTION_GET_INIT_CONFIG,
} from "../../Actions/actions";
import { move } from "react-big-calendar";

interface intialState {
  loading: boolean;
  error: null | string;
  data: any;
  timelineObject: any;
  moveInDate: any;
  miloCurrent: any;
  miloScheduled: any;
  miloCompleted: any;
  reloEvents: any;
  signalUpdate: any;
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {},
  timelineObject: {},
  moveInDate: {},
  miloCurrent: {},
  miloScheduled: {},
  miloCompleted: {},
  reloEvents: {},
  signalUpdate: "",
};

export const getTimelineObject = createAsyncThunk(
  "ACTION_GET_TIMELINE_OBJECT",
  async (data: any) => {
    return timelineService.getTimelineObject(data);
  },
);

const getTimelineObjectFulfield = (state: any, action: any) => {
  state.loading = false;
  state.timelineObject.data = action.payload?.data || "NO_OBJECT_FOUND";
};

export const createTimelineObject = createAsyncThunk(
  "ACTION_CREATE_TIMELINE_OBJECT",
  async (data: any) => {
    return timelineService.createTimelineObject(data);
  },
);

const createTimelineObjectFulfield = (state: any, action: any) => {
  state.loading = false;
  state.timelineObject.data = {
    reloEvent: { ...action.payload?.data?.reloEventDetails },
  };
};

export const updateTimelineObject = createAsyncThunk(
  "ACTION_UPDATE_TIMELINE_OBJECT",
  async (data: any) => {
    return timelineService.updateTimelineObject(data);
  },
);

const updateTimelineObjectFulfield = (state: any, action: any) => {
  state.loading = false;
  state.timelineObject.data.reloEvent = action.meta.arg;
};

export const getMoveInDate = createAsyncThunk(
  "ACTION_GET_MOVE_IN_DATE",
  async (data: any) => {
    return timelineService.getMoveInDate(data);
  },
);

const getMoveInDateFulfield = (state: any, action: any) => {
  state.loading = false;
  state.moveInDate.data = action.payload?.data;
};

export const updateMoveInDate = createAsyncThunk(
  "ACTION_UPDATE_MOVE_IN_DATE",
  async (data: any) => {
    return timelineService.updateMoveInDate(data);
  },
);

const updateMoveInDateFulfield = (state: any, action: any) => {
  state.loading = false;
  state.moveInDate.data = action.payload?.data;
};

export const getMiloEventsCurrent = createAsyncThunk(
  "ACTION_GET_MILO_EVENT_CURRENT",
  async (data: any) => {
    return timelineService.getMiloEvents(data);
  },
);

const getMiloEventsCurrentFulfield = (state: any, action: any) => {
  state.loading = false;
  state.miloCurrent.data = action.payload?.data;
};

export const getMiloEventsScheduled = createAsyncThunk(
  "ACTION_GET_MILO_EVENT_SCHEDULED",
  async (data: any) => {
    return timelineService.getMiloEvents(data);
  },
);

const getMiloEventsScheduledFulfield = (state: any, action: any) => {
  state.loading = false;
  state.miloScheduled.data = action.payload?.data;
};

export const getMiloEventsCompleted = createAsyncThunk(
  "ACTION_GET_MILO_EVENT_COMPLETED",
  async (data: any) => {
    return timelineService.getMiloEvents(data);
  },
);

const getMiloEventsCompletedFulfield = (state: any, action: any) => {
  state.loading = false;
  state.miloCompleted.data = action.payload?.data;
};

export const getReloEvents = createAsyncThunk(
  "ACTION_GET_RELO_EVENTS",
  async (id: string) => {
    return timelineService.getReloEvents(id);
  },
);

const getReloEventsFulfield = (state: any, action: any) => {
  state.loading = false;
  state.reloEvents.data = action.payload?.data || null;
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    signalUpdate(state, action) {
      state.signalUpdate = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getTimelineObject.fulfilled, getTimelineObjectFulfield);
    builder.addCase(
      createTimelineObject.fulfilled,
      createTimelineObjectFulfield,
    );
    builder.addCase(
      updateTimelineObject.fulfilled,
      updateTimelineObjectFulfield,
    );
    builder.addCase(getMoveInDate.fulfilled, getMoveInDateFulfield);
    builder.addCase(
      getMiloEventsCurrent.fulfilled,
      getMiloEventsCurrentFulfield,
    );
    builder.addCase(
      getMiloEventsScheduled.fulfilled,
      getMiloEventsScheduledFulfield,
    );
    builder.addCase(
      getMiloEventsCompleted.fulfilled,
      getMiloEventsCompletedFulfield,
    );
    builder.addCase(getReloEvents.fulfilled, getReloEventsFulfield);
    builder.addCase(updateMoveInDate.fulfilled, updateMoveInDateFulfield);

    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default timelineSlice.reducer;
export const { signalUpdate, reset } = timelineSlice.actions;
