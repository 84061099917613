import {
  GET_WHATS_NEW,
  CREATE_WHATS_NEW,
  UPDATE_WHATS_NEW,
  DELETE_WHATS_NEW,
  TOGGLE_WHATS_NEW,
  SET_NEWS_SEQUENCE,
} from "../../resources/constants";
import axios from "../axios-client";

export default class WhatsNewService {
  public static async getWhatsNew() {
    return await axios.get(GET_WHATS_NEW);
  }
  public static async getActiveWhatsNew() {
    return await axios.get(GET_WHATS_NEW + "/?active=true");
  }

  public static async createWhatsNew(data: any) {
    return await axios.put(CREATE_WHATS_NEW, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async updateWhatsNew(data: any) {
    return await axios.post(UPDATE_WHATS_NEW, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async deleteWhatsNew(id: any) {
    return await axios.del(DELETE_WHATS_NEW + "/" + id);
  }

  public static async toggleWhatsNew(id: any) {
    return await axios.post(TOGGLE_WHATS_NEW + "/" + id, {});
  }

  public static async setWhatsNew(data: any) {
    return await axios.put(SET_NEWS_SEQUENCE, data);
  }
}
