import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import "./global.scss";
import Sidebar from "./pages/Sidebar/Sidebar";
import HeaderComponent from "./pages/Header/HeaderComponent";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "./store/hooks";
import AnonymousRoutes from "./pages/routes/AnonymousRoutes";
import { Layout } from "antd";
import AuthenticatedRoutes from "./pages/routes/AuthenticatedRoutes";
import useToggle from "./customHooks/useToggle";
import { getUserInfo } from "./store/Login/slice";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./utils/history";
import Loader from "./components/loader/Loader";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";
import { toTop } from "./utils/toTop";
import { addStyle } from "./utils/addStyles";
import { setLastURL, token } from "./services/axios-client";
import axios from "./services/axios-client";
import { TOKEN_EXCHANGE } from "./resources/constants";
import SessionPopup from "./components/SessionPopup/SessionPopup";
import ConcentPopup from "./components/ConcentPopup/ConcentPopup";
// axios.get(TOKEN_EXCHANGE);

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const loginDetails = useAppSelector((state) => state?.login?.user);
  const [openSider, toggleSider] = useToggle(false);
  const [isAppLoading, setAppLoading] = useState(true);
  const showSlider = useAppSelector((state) => state?.displayConfig.showSlider);
  const navigateTo = useAppSelector((state) => state?.displayConfig.navigateTo);

  const isLoading = useSelector(
    (state: any) => state?.loader?.isLoading || false,
  );

  useEffect(() => {
    if (searchParams.get("redirect")) {
      setLastURL();
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("lastTime")) {
      if (
        !location.pathname.includes("first-time-user") &&
        !location.pathname.includes("first-time-login") &&
        !location.pathname.includes("forgot-password")
      ) {
        navigate("/login");
      }
    } else {
      if (
        location.pathname.includes("first-time-user") ||
        location.pathname.includes("forgot-password")
      ) {
        localStorage.clear();
        navigate(window.location.pathname);
      }
      const pathname = window.location.pathname; //returns the current url minus the domain name
      if (location.pathname === "/login") navigate("/");
      else navigate(`${pathname}${location.search}`);
    }
  }, [loginDetails]);

  const infoApiCalled = useRef(false);

  useEffect(() => {
    if (infoApiCalled.current) return;
    infoApiCalled.current = true;
    if (localStorage.getItem("lastTime")) {
      if (token.publicKey) {
        dispatch(getUserInfo());
      } else {
        setTimeout(() => {
          dispatch(getUserInfo());
        }, 500);
      }
    }
    setTimeout(() => {
      setAppLoading(false);
    }, 1000);

    if (navigator.userAgent.search("Windows") !== -1) {
      addStyle("../global/css/scroll.css");
    }
  }, [infoApiCalled]);

  useEffect(() => {
    toTop();
  }, [location.pathname]);

  // temporary code to hide cost estimates
  useEffect(() => {
    if (process.env.REACT_APP_SHOW_COST_ESTIMATE === "false") {
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `.dashBoardCommonStyle .ant-menu-item[data-menu-id*="-cost-estimates"] {
      display: none !important;
  }`;
      document.getElementsByTagName("head")[0].appendChild(style);
    }
  }, []);

  const [showEnvLabel, setShowEnvLabel] = useState(false);

  useEffect(() => {
    if (
      ["localhost", "test.reloportal.com", "dev.reloportal.com"].includes(
        window.location.hostname,
      )
    ) {
      setShowEnvLabel(true);
    }
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        {/* <div>{isAppLoading && <Loader />}</div> */}
        <div>{isLoading ? <Loader /> : null}</div>
        {!localStorage.getItem("lastTime") ? (
          <AnonymousRoutes />
        ) : (
          <Layout className="mainLayout dashBoardLayout dashBoardCommonStyle">
            {window.innerWidth > 768 ? (
              showSlider && (
                <Sidebar openSider={openSider} toggleSider={toggleSider} />
              )
            ) : (
              <Sidebar openSider={openSider} toggleSider={toggleSider} />
            )}
            <Layout className="">
              <HeaderComponent
                openSider={openSider}
                toggleSider={toggleSider}
                navigateTo={navigateTo}
              />
              <div className="mainContentLayout">
                <AuthenticatedRoutes />
              </div>
            </Layout>
            <SessionPopup />
            <ConcentPopup />
          </Layout>
        )}
        {showEnvLabel && (
          <div className="envLabel">
            {process.env.NODE_ENV === "development" ||
            ["dev.reloportal.com"].includes(window.location.hostname)
              ? "DEV"
              : "TEST"}
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default App;
