import {
  DELETE_TRANSFEREE,
  GET_TRANSFEREE_HISTORY_URL,
  GET_TRANSFEREE_LIST_URL,
  UPDATE_TRANSFEREE_STATUS,
  countriesUrl,
  SET_PII_ACKNOWLEDGEMENT,
  GET_ALL_TRANSFEREES,
} from "../resources/constants";
import AxiosClient from "./axios-client";
import Environment from "../utils/environment";
import { setCredentials } from "../store/Login/slice";
import axios from "axios";
const notLogedIn = { error: "not logged in due to no network" };

export interface loginPageResponse {
  data?: object;
  success?: boolean;
  message?: string;
}

interface TransfereeDetail {
  id: string;
  transfereeId: string;
  divFieldMappingId: string;
  value: string;
}

interface Transferee {
  id: string;
  firstName: string;
  lastName: string;
  miloId: string;
  policyId: string | null;
  policy: string | null;
  transfereeDetails: TransfereeDetail[];
  consultant: Consultant;
}

interface Consultant {
  firstName: string;
  lastName: string;
}

interface TransfereesData {
  data: {
    transferees: Transferee[];
    totalCount: number;
  };
  success: boolean;
  message: string;
}

export default class transfereeServices {
  static async getCountry() {
    const result = await AxiosClient.get(countriesUrl);
    setCredentials(result);
    return result;
  }

  static async getTransferees(body: any) {
    try {
      const result: TransfereesData = await AxiosClient.post(
        GET_TRANSFEREE_LIST_URL,
        body,
      );

      return result.data;
    } catch (error: any) {
      throw error.message.message;
    }
  }

  public static async getTransfereeHistory(payLoad: any) {
    try {
      const result: any = await AxiosClient.get(
        `${GET_TRANSFEREE_HISTORY_URL}?transfereeId=${payLoad?.id}&limit=${payLoad?.limit}&page=${payLoad?.page}`,
      );

      return result?.data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getCountries() {
    return Environment.isOffline()
      ? await transfereeServices.getCountry()
      : notLogedIn;
  }

  public static async getAllTransferees(body: any) {
    return Environment.isOffline()
      ? await transfereeServices.getTransferees(body)
      : notLogedIn;
  }

  public static async updateTransfereeStatus(data: any) {
    return AxiosClient.post(UPDATE_TRANSFEREE_STATUS, data);
  }

  public static async setPIIAcknowledgement(data: any) {
    return AxiosClient.post(SET_PII_ACKNOWLEDGEMENT, data);
  }

  public static async deleteTransferee(id: any) {
    return AxiosClient.del(DELETE_TRANSFEREE + "/" + id);
  }

  public static async fetchAllTransferees() {
    return AxiosClient.get(GET_ALL_TRANSFEREES);
  }
}
