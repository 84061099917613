import { createSlice, current } from "@reduxjs/toolkit";
import { ACTION_FILTERS } from "../../Actions/actions";
import { dashboardFilters } from "../../../src/components/Filters/Filters.constants";
import dayjs from "dayjs";
import { getDisplayDate } from "../../utils/date";

export interface filterType {
  clientDashboardFilters: any;
  data: any;
  transfereeFilters: any;
}

const initialFilter = {
  divisionId: null,
  startDate: null,
  endDate: null,
};

const initialFilterAuth = {
  divisionId: null,
  startAuthDate: 0,
  endAuthDate: 0,
};

const initialState: filterType = {
  clientDashboardFilters: {
    [dashboardFilters.TOTAL_YTD_SPEND]: initialFilter,
    [dashboardFilters.TOTAL_YTD_AUTHORISATIONS]: initialFilterAuth,
    [dashboardFilters.TOTAL_YTD_ACTIVE_FILES]: initialFilter,
    [dashboardFilters.UPCOMING_VIAS_EXPIRY]: { days: 90 },
    [dashboardFilters.AUTHORISATIONS_BY_POLICY]: initialFilterAuth,
    [dashboardFilters.SPENDS_BY_POLICY]: initialFilter,
    [dashboardFilters.EXCEPTIONS_BY_POLICY]: initialFilter,
    [dashboardFilters.MAPS_DATA]: {
      map_data_filter: "OverAll",
      domestic: "false",
      // startDate: `${new Date().getFullYear()}-01-01`,
      // endDate: new Date().toISOString().split("T")[0]
    },
    [dashboardFilters.DIVISION]: { divisionId: null },
    [dashboardFilters.SERVICE_BY_POLICY]: { divisionId: null },
  },
  transfereeFilters: {
    page: 1,
    limit: 10,
    showFilters: false,
    filters: {
      country: undefined,
      state: undefined,
      policyId: undefined,
      query: undefined,
      status: undefined,
      notification: undefined,
    },
  },
  data: {},
};

const Filters = createSlice({
  name: ACTION_FILTERS,
  initialState,
  reducers: {
    updateDashboardFilters: (state, action) => {
      // state.clientDashboardFilters = {...state.clientDashboardFilters,[action.payload.type]: action.payload};
    },

    updateData: (state, action) => {
      state.clientDashboardFilters = {
        ...state.clientDashboardFilters,
        [action.payload.type]: action.payload,
      };
    },

    updateTransfereeFilters: (state, action) => {
      state.transfereeFilters = action.payload;
    },
    resetClientDashboardFilters: (state) => {
      state.clientDashboardFilters = initialState.clientDashboardFilters;
    },
  },
  extraReducers: function (builder): void {},
});

export const {
  updateDashboardFilters,
  updateData,
  updateTransfereeFilters,
  resetClientDashboardFilters,
} = Filters.actions;

export default Filters.reducer;
