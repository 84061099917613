import axios from "../axios-client";
import {
  GET_CRI_GROUP_LISTING,
  GET_CRI_GROUP_ROLES,
  GET_CRI_GROUP_VIEW,
  CREATE_CRI_GROUP,
  UPDATE_CRI_GROUP,
} from "../../resources/constants";

class criGroupService {
  public static async getListing(data: any) {
    return axios.get(
      GET_CRI_GROUP_LISTING + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getView(id: string) {
    return axios.get(GET_CRI_GROUP_VIEW + "/" + id);
  }

  public static async getRoles(data: any) {
    return axios.get(
      GET_CRI_GROUP_ROLES + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async create(data: any) {
    return axios.put(CREATE_CRI_GROUP, data);
  }

  public static async update(data: any) {
    return axios.post(UPDATE_CRI_GROUP, data);
  }
}

export default criGroupService;
