import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import costEstimatesListingService, {
  getCostEstimatesListing,
} from "../../../services/CostEstimates/CostEstimatesListing";
import { ACTION_COST_ESTIMATES_LISTING } from "../../../Actions/actions";

interface intialState {
  loading: boolean;
  error: null | string;
  data: {
    table: any[];
    count?: number;
  };
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {
    table: [],
    count: 0,
  },
};

export const costEstimatesListing = createAsyncThunk(
  ACTION_COST_ESTIMATES_LISTING,
  async (params: getCostEstimatesListing) => {
    return costEstimatesListingService.getCostEstimatesListing(params);
  },
);

const costEstimatesFullfield = (state: any, action: any) => {
  state.loading = false;
  state.data = action.payload?.data;
};

const costEstimatesListingSlice = createSlice({
  name: "costEstimatesListing",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(costEstimatesListing.fulfilled, costEstimatesFullfield);
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default costEstimatesListingSlice.reducer;
export const { resetState } = costEstimatesListingSlice.actions;
